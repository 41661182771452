<div class="d-flex gap-2 flex-column mt-4">
  <div class="fw-bolder">Add notes</div>
  <div [formGroup]="form">
    <ff-textarea
      [id]="'AddNoteNotesTextArea'"
      formControlName="notesControl"
      [numberOfColumns]="1"
      [numberOfLines]="3"
      [autoSize]="true"
      (OnChange)="notesChange($event)"
      [placeHolder]="'Add notes here'"
    ></ff-textarea>
  </div>
  <div class="d-flex gap-2 mt-2">
    <ffx-button
      [id]="'AddNoteCancelButton'"
      [buttonSize]="buttonSizeEnum.Medium"
      [buttonType]="buttonTypeEnum.Secondary"
      [enabled]="true"
      [buttonTooltip]="false"
      [textValue]="'Cancel'"
      (OnClick)="Cancel()"
    ></ffx-button>
    <ffx-button
      [id]="'AddNoteSaveButton'"
      [buttonSize]="buttonSizeEnum.Medium"
      [buttonType]="buttonTypeEnum.Primary"
      [enabled]="true"
      [buttonTooltip]="false"
      [textValue]="'Save'"
      (OnClick)="Save()"
    ></ffx-button>
  </div>
  <ff-separator></ff-separator>
</div>
