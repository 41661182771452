<div>
  <div class="mb-2">
    <ff-label
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
      [showTitle]="showTitle"
      [controlId]="id"
    />
    <ng-content />
  </div>

  <ff-subtitle *ngIf="showSubtitle" [subtitle]="subtitle" />

  <ff-control-validation
    *ngIf="hasErrors && showErrorText"
    [errors]="control.errors"
    [label]="errorMessageSuffix || label"
  />

  <div>
    <p-dropdown
      [formControl]="formControl"
      [options]="options"
      appendTo="body"
      (onChange)="onChangeEvent($event)"
      [placeholder]="placeHolder ?? 'invisiblePlaceholder'"
      [class.ng-invalid]="hasErrors"
      [class.ng-dirty]="hasErrors"
      [class.invisible-placeholder]="!placeHolder"
      styleClass="ffx-select"
    >
      <ng-template let-value pTemplate="selectedItem">
        <div
          *ngIf="value"
          class="d-flex align-items-center gap-2"
          [ngClass]="{
            'flex-row-reverse justify-content-end':
              visualType === VisualType.Colors
          }"
        >
          <div
            *ngIf="
              visualType !== VisualType.Colors ||
              visualMode === VisualMode.Text ||
              visualMode === VisualMode.VisualText
            "
            [ngStyle]="{
              color:
                visualType === VisualType.Colors &&
                visualMode === VisualMode.Text
                  ? getMappingVisualValue(value)
                  : ''
            }"
          >
            {{ value.displayName || value }}
          </div>
          <div
            *ngIf="
              visualType === VisualType.Colors &&
              (visualMode === VisualMode.Visual ||
                visualMode === VisualMode.VisualText)
            "
            class="shapeSize"
            [ngClass]="{
              square: shapeType === ShapeType.Square,
              circle: shapeType === ShapeType.Circle
            }"
            [class.rounded-circle]="shapeType === ShapeType.Circle"
            [style.backgroundColor]="getMappingVisualValue(value)"
          ></div>
        </div>
      </ng-template>
      <ng-template let-option pTemplate="item">
        <div
          *ngIf="option"
          class="d-flex align-items-center gap-2"
          [ngClass]="{
            'flex-row-reverse justify-content-end':
              visualType === VisualType.Colors
          }"
        >
          <div
            *ngIf="
              visualType !== VisualType.Colors ||
              visualMode === VisualMode.Text ||
              visualMode === VisualMode.VisualText
            "
            [ngStyle]="{
              color:
                visualType === VisualType.Colors &&
                visualMode === VisualMode.Text
                  ? getMappingVisualValue(option)
                  : ''
            }"
          >
            {{ option.displayName || option }}
          </div>
          <div
            *ngIf="
              visualType === VisualType.Colors &&
              (visualMode === VisualMode.Visual ||
                visualMode === VisualMode.VisualText)
            "
            class="shapeSize"
            [ngClass]="{
              square: shapeType === ShapeType.Square,
              circle: shapeType === ShapeType.Circle
            }"
            [class.rounded-circle]="shapeType === ShapeType.Circle"
            [style.backgroundColor]="getMappingVisualValue(option)"
          ></div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
</div>
