import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Overlay } from 'primeng/overlay';
import { MultiSelect, MultiSelectChangeEvent } from 'primeng/multiselect';
import {
  ShapeType,
  VisualMapping,
  VisualMode,
  VisualType,
} from 'src/app/models/Visual';
import { FFInputComponentBase } from '../ff-base/ff-input-component-base';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'ff-multiselect',
  templateUrl: './ff-multiselect.component.html',
  styleUrls: ['./ff-multiselect.component.scss'],
})
export class FFMultiselectComponent
  extends FFInputComponentBase
  implements OnInit
{
  @ViewChild(MultiSelect) multiSelect?: MultiSelect;

  @Input() options?: any[];
  @Input() defaultValues?: any[];
  @Input() visualType?: VisualType;
  @Input() visualMode?: VisualMode;
  @Input() visualMappings?: VisualMapping[];
  @Input() shapeType?: ShapeType;
  VisualMode = VisualMode;
  VisualType = VisualType;
  ShapeType = ShapeType;

  constructor(
    private el: ElementRef,
    protected ngControl: NgControl,
  ) {
    super(ngControl);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.formControl.patchValue(this.control.value ?? this.defaultValues);
  }

  /**
   * @description Update the overlay options to position the panel below the multiselect
   * primarily introduced for table
   * @returns void
   */
  updateOverlayOptions() {
    setTimeout(() => {
      const multiSelectDom: HTMLElement =
        this.el.nativeElement.querySelector('.p-multiselect');
      const overlayPanel = this.multiSelect?.overlayViewChild as Overlay;
      if (overlayPanel) {
        const domElement: HTMLElement =
          overlayPanel.overlayViewChild?.nativeElement;
        if (domElement) {
          domElement.style.top = `${
            multiSelectDom.getBoundingClientRect().bottom + window.scrollY
          }px`;
          domElement.style.left = `${
            multiSelectDom.getBoundingClientRect().left
          }px`;
        }
      }
    });
  }

  /**
   * @description Remove a value from the control
   * @param event The event that triggered the action
   * @param value The value to remove
   * @returns void
   */
  removeValue(event: Event, value: string): void {
    // Prevent from opening the multiselect panel
    event.stopPropagation();

    let controlValue = this.formControl.value as string[];
    controlValue.splice(controlValue.indexOf(value), 1);
    this.formControl.setValue(controlValue);
    super.onChangeEvent(this.formControl.value);
    this.updateOverlayOptions();
  }

  /**
   * @description Override the onChangeEvent to update values only if the event is not from the panel
   * @param event
   */
  onValueChangeEvent(event: MultiSelectChangeEvent): void {
    this.updateOverlayOptions();
    // onPanelHide and actions in panel have originalEvent so we skip them here
    // because we want those events to only update when panel is closed
    if (!event.originalEvent) {
      super.onChangeEvent(this.formControl.value);
    }
  }

  /**
   * @description Update the values when the panel is closed
   */
  onHide() {
    super.onChangeEvent(this.formControl.value);
  }

  getMappingVisualValue(value: string): string | undefined {
    const visualMapping = this.visualMappings?.find(
      (mapping) => mapping.value === value,
    );
    return visualMapping ? visualMapping.visualValue : undefined;
  }
}

export interface MultiSelectEvent {
  itemValue: string;
  originalEvent: PointerEvent;
  value: string[];
}
