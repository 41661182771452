import { Component, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FFInputComponentBase } from '../../ff-base/ff-input-component-base';
import {
  ShapeType,
  VisualMapping,
  VisualMode,
  VisualType,
} from 'src/app/models/Visual';

@Component({
  selector: 'ff-select',
  templateUrl: './ff-select.component.html',
  styleUrls: ['./ff-select.component.scss'],
  providers: [{ provide: NgControl, useValue: NgControl }],
})
export class FFSelectComponent extends FFInputComponentBase implements OnInit {
  @Input() options?: any[];
  @Input() defaultValue: string = '';
  @Input() addNullOption: boolean = true;
  @Input() visualType?: VisualType;
  @Input() visualMode?: VisualMode;
  @Input() visualMappings?: VisualMapping[];
  @Input() shapeType?: ShapeType;
  VisualMode = VisualMode;
  VisualType = VisualType;
  ShapeType = ShapeType;

  override ngOnInit(): void {
    super.ngOnInit();
    this.formControl.valueChanges.subscribe((value: any) => {
      this.formatValue(value);
    });

    this.formatValue(this.formControl.value);
  }

  override onChangeEvent(event: any): void {
    this.formControl.setValue(event.value);
    super.onChangeEvent([this.formControl.value]);
  }

  formatValue(value: any): void {
    if (Array.isArray(value)) {
      value.length == 1
        ? this.formControl.patchValue(value[0])
        : this.formControl.patchValue(this.defaultValue);
    }
  }

  getMappingVisualValue(value: string): string | undefined {
    const visualMapping = this.visualMappings?.find(
      (mapping) => mapping.value === value,
    );
    return visualMapping ? visualMapping.visualValue : undefined;
  }
}
