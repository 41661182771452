import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertType } from '../../../visual-components/components/ff-message-divider/ff-message-divider.component';
import { URIService } from '../../../common/services/uri.service';

@Component({
  selector: 'delegate-step-success-dialog',
  templateUrl: './delegate-step-success-dialog.component.html',
})
export class DelegateStepSuccessDialogComponent {
  constructor(
    public activeModal: NgbActiveModal,
    private _uriService: URIService,
  ) {}

  @Input() title!: string;
  @Input() assignedTo!: string;
  @Input() flowTitle!: string;
  @Input() stepIndex!: number;
  @Input() avatarImage!: string;
  @Input() logo!: string;
  defaultLogoImage: string = 'assets/default_logo.png';
  public alertTypeEnum = AlertType;

  public toDashboard(): void {
    this._uriService.redirectToFlowFormaFormsList();
  }
}
