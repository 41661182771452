import { Pipe } from '@angular/core';
import { BaseFFxDatePipe } from './base-ffx-date.pipe';

@Pipe({
  name: 'timeOnlyInputPipe',
})
export class TimeOnlyInputPipe extends BaseFFxDatePipe {
  override get formatString(): string {
    return 'HH:mm';
  }
}
