<div>
  <div class="mb-2">
    <ff-label
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
      [showTitle]="showTitle"
      [controlId]="id"
    />
    <ng-content />
  </div>

  <ff-subtitle *ngIf="showSubtitle" [subtitle]="subtitle" />

  <ff-control-validation
    *ngIf="hasErrors && showErrorText"
    [errors]="control.errors"
    [label]="label"
  />
  <div
    class="form-control d-flex rounded-1"
    [class.is-invalid]="hasErrors"
    [title]="label"
    [class.isInputReadOnly]="isInputReadOnly"
    [id]="id"
  >
    <a
      class="overflow-hidden"
      [ngbTooltip]="formControl.value ? formControl.value : null"
      [triggers]="'hover'"
      [attr.href]="formControl.value ? formControl.value : null"
      target="_blank"
      ><ng-container *ngIf="formControl.value">
        {{ hyperlinkLabel || formControl.value }}
      </ng-container>
      <span *ngIf="!formControl.value" class="invisible"
        >Nothing to see here</span
      >
    </a>
  </div>
</div>
