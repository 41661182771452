import { Question } from './Question';

export class Lookup extends Question<KeyValuePair | null> {}

export class KeyValuePair {
  constructor(key: number, value: string) {
    this.key = key;
    this.value = value;
  }

  key: number;
  value: string;

  static isKeyValuePair(obj: any): obj is KeyValuePair {
    return (
      obj &&
      typeof obj === Types.Object &&
      Object.keys(obj).length === 2 &&
      typeof obj.key === Types.Number &&
      typeof obj.value === Types.String
    );
  }
}

export class KeyValuePairDisplay {
  constructor(key: number, value: string) {
    this.key = key;
    this.value = value;
    this.displayValue = value !== '' ? value : KeyValuePairSpecialValue.NoValue;
  }

  key: number;
  value: string;
  displayValue: string;

  static isKeyValuePairDisplay(obj: any): obj is KeyValuePairDisplay {
    return (
      obj &&
      typeof obj === Types.Object &&
      Object.keys(obj).length === 3 &&
      typeof obj.key === Types.Number &&
      typeof obj.value === Types.String &&
      typeof obj.displayValue === Types.String
    );
  }
}

enum KeyValuePairSpecialValue {
  NoValue = '(No value)',
}

export enum Types {
  Object = 'object',
  Number = 'number',
  String = 'string',
}
