<div>
  <div class="mb-2">
    <ff-label
      [label]="tableQuestion.title"
      [tooltip]="tableQuestion.description"
      [required]="tableQuestion.required"
      [showTitle]="tableQuestion.showTitle"
    />
    <ng-content />
  </div>
  <label
    *ngIf="tableQuestion.allowExportToCsv"
    (click)="export(tableQuestion)"
    class="btn btn-outline-primary float-end cursor-pointer"
  >
    <div class="fs-6 fw-bold ms-2">Export</div>
  </label>
  <ul
    ngbNav
    #nav="ngbNav"
    [(activeId)]="selectedTableViewMode"
    (activeIdChange)="changeActiveView($event)"
    class="nav-tabs border-0 mb-3"
  >
    <li
      [ngbNavItem]="TableViewMode[TableViewMode.Card]"
      class="rounded-start border border-secondary"
    >
      <button
        ngbNavLink
        class="border-0 rounded-0 rounded-start text-secondary"
      >
        <i class="bi bi-card-text"></i>
      </button>
      <ng-template ngbNavContent *ngIf="tableQuestion.visible">
        <ng-container *ngFor="let rowData of cardViewData">
          <table-question-card
            [tableData]="tableData"
            [question]="tableQuestion"
            [form]="form"
            [rowIndex]="rowData.rowIndex"
            [flowId]="flowId"
            [isRowsFixed]="tableQuestion.fixedRows"
            [isRowNumbersHidden]="tableQuestion.showRowNumbers"
            [isRemoveButtonHidden]="tableQuestion.hideRemoveButton"
            [readOnly]="readOnly"
            [enableRowDuplication]="tableQuestion.enableRowDuplication"
            (rowEvent)="onRowEvent($event)"
            (tableValueUpdateEvent)="updateQuestionValue($event)"
          />
        </ng-container>
        <ng-container *ngIf="footerHasData">
          <table-question-card
            [tableFooter]="tableData.footerColumns"
            [question]="tableQuestion"
            [form]="form"
            [isFooter]="true"
            [readOnly]="readOnly"
          />
        </ng-container>
      </ng-template>
    </li>
    <li
      [ngbNavItem]="TableViewMode[TableViewMode.Grid]"
      class="rounded-end border border-secondary"
    >
      <button ngbNavLink class="border-0 rounded-0 rounded-end text-secondary">
        <i class="bi bi-grid-3x2"></i>
      </button>
      <ng-template ngbNavContent *ngIf="tableQuestion.visible">
        <table-features
          [tableData]="tableData"
          [question]="tableQuestion"
          [form]="form"
          [flowId]="flowId"
          [readOnly]="readOnly"
          [isRowsFixed]="tableQuestion.fixedRows"
          [enableRowDuplication]="tableQuestion.enableRowDuplication"
          [isRemoveButtonHidden]="tableQuestion.hideRemoveButton"
          [firstViewingItemIndex]="firstViewingItemIndex"
          [viewableRows]="viewableRows"
          (rowEvent)="onRowEvent($event)"
          (tableValueUpdateEvent)="updateQuestionValue($event)"
          (viewingData)="updateCardViewData($event)"
        ></table-features>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav"></div>
  <ng-container *ngIf="!tableQuestion.fixedRows && !readOnly">
    <label
      (click)="addRow()"
      class="btn btn-outline-primary cursor-pointer mt-2 p-0"
    >
      <div
        class="fs-6 fw-bold d-flex px-2 py-1 justify-content-center align-items-center gap-2"
      >
        <i class="bi bi-plus-square"></i
        >{{
          tableQuestion.newRowCommandLabel
            ? tableQuestion.newRowCommandLabel
            : "Add another"
        }}
      </div>
    </label>
  </ng-container>
</div>
