import { Component, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FFInputComponentBase } from '../../ff-base/ff-input-component-base';
import { FormatType } from '../../../../models/FormatType';
import { InputNumberInputEvent } from 'primeng/inputnumber';

@Component({
  selector: 'ff-input-number',
  templateUrl: './ff-input-number.component.html',
  styleUrls: ['./ff-input-number.component.scss'],
})
export class FFInputNumberComponent
  extends FFInputComponentBase
  implements OnInit
{
  MAXIMUMPOSSIBLENUMBER: number = 9999999999;
  MINIMUMPOSSIBLENUMBER: number = -9999999999;

  @Input() culture?: string;
  @Input() decimalPlaces?: number;
  @Input() format?: FormatType;
  @Input() maximum?: number;
  @Input() minimum?: number;
  @Input() step?: number;
  @Input() isInputReadOnly?: boolean;
  @Input() disableThousandGrouping?: boolean = true;

  protected readonly FormatType = FormatType;

  constructor(public override control: NgControl) {
    super(control);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    // The form control value is a string, because of that the default value is "" instead of null
    // if the value is "" it shows 0 instead of empty, null shows empty.
    if (this.formControl.value === '') {
      this.formControl.patchValue(null, { emitEvent: false });
    }
  }

  updateValue(): void {
    if (this.isInputReadOnly) return;

    this.onChangeEvent(this.formControl.value);
  }

  /**
   * @description This method is called when the input value changes,
   * it checks if the value is greater than the maximum value allowed.
   * If it is, it sets the value to the maximum value allowed.
   * @param {InputNumberInputEvent} event Primeng input number event
   */
  onInput(event: InputNumberInputEvent): void {
    const inputValue = event.value;
    if (this.maximum && Number(inputValue) > this.maximum) {
      this.formControl.patchValue(this.maximum);
    } else if (inputValue && Number(inputValue) > this.MAXIMUMPOSSIBLENUMBER) {
      this.formControl.patchValue(this.MAXIMUMPOSSIBLENUMBER);
    }

    // Access the original DOM event to update the displayed value
    const originalEvent = event.originalEvent as InputEvent;
    const inputElement = originalEvent.target as HTMLInputElement;
    inputElement.value = event.formattedValue;
  }
}
