import { Component, Input, OnInit } from '@angular/core';
import {
  VisualType,
  VisualMode,
  VisualMapping,
  ShapeType,
} from 'src/app/models/Visual';
import { FFInputComponentBase } from '../ff-base/ff-input-component-base';

@Component({
  selector: 'ff-checkbox',
  templateUrl: './ff-checkbox.component.html',
  styleUrls: ['./ff-checkbox.component.scss'],
})
export class FFCheckboxComponent
  extends FFInputComponentBase
  implements OnInit
{
  @Input() options?: any[];
  @Input() defaultValue?: any[];
  @Input() visualType?: VisualType;
  @Input() visualMode?: VisualMode;
  @Input() visualMappings?: VisualMapping[];
  @Input() imageWidth?: number;
  @Input() shapeType?: ShapeType;
  VisualMode = VisualMode;
  VisualType = VisualType;
  ShapeType = ShapeType;

  override ngOnInit() {
    super.ngOnInit();
    this.formControl.patchValue(this.control.value ?? this.defaultValue);
  }

  override onChangeEvent(): void {
    super.onChangeEvent(this.formControl.value);
  }

  getMappingVisualValue(value: string): string | undefined {
    const visualMapping = this.visualMappings?.find(
      (mapping) => mapping.value === value,
    );
    return visualMapping ? visualMapping.visualValue : undefined;
  }
}
