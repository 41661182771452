import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertType } from '../../../visual-components/components/ff-message-divider/ff-message-divider.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateOnlyInputPipe } from 'src/app/common/pipes/ffx-date-pipes/date-only-input.pipe';
import { CustomDayPipe } from 'src/app/common/pipes/ffx-date-pipes/custom-day.pipe';

@Component({
  selector: 'app-step-reopening-confirmation-dialog',
  templateUrl: './step-reopening-confirmation-dialog.component.html',
  styleUrls: ['./step-reopening-confirmation-dialog.component.scss'],
})
export class StepReopeningConfirmationDialogComponent {
  alertTypeEnum: typeof AlertType = AlertType;

  currentDate: Date;
  tomorrowDate: Date;
  nextWeekDate: Date;
  radioOptions: { date?: Date; option: string }[] = [];
  radioOptionsString: string[] = [];
  showDatePicker: boolean = false;

  @Input() avatarImage?: string;
  @Input() stepTitle!: string;
  @Input() stepId!: string;
  @Input() stepNumber!: number;
  @Input() stepAssignedTo: string = '';

  public form = new FormGroup({
    radioSelect: new FormControl('', Validators.required),
    dateSelect: new FormControl(''),
    selectedDate: new FormControl<Date | undefined>(undefined),
  });

  constructor(
    public activeModal: NgbActiveModal,
    private dateOnlyInputPipe: DateOnlyInputPipe,
    private customDayPipe: CustomDayPipe,
  ) {
    this.currentDate = this.createDateWithDefaultTime();
    this.tomorrowDate = this.createDateWithDefaultTime(this.currentDate, 1);
    this.nextWeekDate = this.createDateWithDefaultTime(this.currentDate, 7);

    this.radioOptions = [
      {
        date: this.currentDate,
        option: `Today`,
      },
      {
        date: this.tomorrowDate,
        //Date example: Tomorrow, Tuesday 12
        option: `Tomorrow, ${this.customDayPipe.transform(this.tomorrowDate)}`,
      },
      {
        date: this.nextWeekDate,
        //Date example: Next week, Monday 12
        option: `Next week, ${this.customDayPipe.transform(this.nextWeekDate)}`,
      },
      {
        date: undefined,
        option: 'Pick a date',
      },
    ];
    this.radioOptionsString = this.radioOptions.map((option) => option.option);
  }

  radioBtnChange(event: string): void {
    this.resetDateSelectValidation();

    if (event === 'Pick a date') this.applyDateSelectValidation();
    else {
      let selectedOption = this.radioOptions.find(
        (option) => option.option === event,
      );

      this.form.controls.radioSelect.setValue(selectedOption!.option);
      this.form.controls.selectedDate.setValue(selectedOption!.date);
    }
  }

  dateChange(event: string): void {
    if (event !== 'Pick a date') {
      const dateTransform = this.dateOnlyInputPipe.transform(event)!;

      this.form.controls.dateSelect.setValue(dateTransform);
      const convertedDate = new Date(dateTransform);
      this.form.controls.selectedDate.setValue(convertedDate);
    }
  }

  submitForm(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.activeModal.close(this.form.value.selectedDate!);
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  resetDateSelectValidation(): void {
    this.showDatePicker = false;
    this.form.controls.dateSelect.reset();
    this.form.controls.dateSelect.clearValidators();
    this.form.controls.dateSelect.updateValueAndValidity();
  }

  applyDateSelectValidation(): void {
    this.showDatePicker = true;
    this.form.controls.dateSelect.setValidators([Validators.required]);
    this.form.controls.dateSelect.updateValueAndValidity();
  }

  private createDateWithDefaultTime(
    baseDate: Date = new Date(),
    daysToAdd: number = 0,
  ): Date {
    let newDate = new Date(baseDate);
    newDate.setUTCHours(0, 0, 0, 0);
    newDate.setDate(baseDate.getDate() + daysToAdd);
    return newDate;
  }
}
