import { Component, Injector } from '@angular/core';
import { NgControl } from '@angular/forms';

import { FFDateAndTimeComponentBase } from '../ff-base/ff-dateAndTime-component-base';
import { DateOnlyInputPipe } from 'src/app/common/pipes/ffx-date-pipes/date-only-input.pipe';

@Component({
  selector: 'ff-date-only',
  templateUrl: './ff-date-only.component.html',
  providers: [{ provide: NgControl, useValue: NgControl }],
})
export class FFDateOnlyComponent extends FFDateAndTimeComponentBase {
  constructor(injector: Injector) {
    super(injector);
    this.dateOnlyInputPipe = injector.get(DateOnlyInputPipe);
  }
  dateOnlyInputPipe: DateOnlyInputPipe;

  override formatDate(value: string | null): string | null {
    if (!value) {
      return null;
    }

    if (!this.isValidDateTime(value)) throw new Error('Invalid date: ' + value);

    const formattedDate = this.dateOnlyInputPipe.transform(value);
    return formattedDate == '' ? null : formattedDate;
  }
}
