import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { QuestionEvent } from '../steps/step-detail/step-detail.component';
import { BaseQuestion } from '../../models/Question';
import { FormGroup } from '@angular/forms';
import { RuleTriggerEvent } from '../../common/services/flows/flow.service';
import { FileUploadDetails } from 'src/app/models/FileUpload';

@Directive()
export class QuestionBaseComponent<T> {
  @Input() question!: BaseQuestion;
  @Input() form!: FormGroup;
  @Input() readOnly!: boolean;
  @Input() flowId!: string;
  @Input() showComment!: boolean;
  @Input() showErrorText: boolean = true;
  @Input() showSubtitle: boolean = true;
  @Output() valueChanged = new EventEmitter<QuestionEvent>();

  get shouldShowSubtitle(): boolean {
    const formControl = this.form.get(this.question.id);

    if (!this.showSubtitle) {
      return false;
    }

    // Check if question is in Readonly mode.
    if (this.readOnly) {
      return false;
    }

    // If the question is disabled, it should show subtitle.
    if (formControl?.disabled) {
      return true;
    }

    // If control is valid should show subtitle.
    if (formControl?.valid) {
      return true;
    }

    // If control is invalid, untouched and pristine should show subtitle.
    return !!(
      !formControl?.valid &&
      !formControl?.touched &&
      formControl?.pristine
    );
  }

  /**
   * @description Function that is triggered when a question value is changed or event is fired.
   * Does nothing if question is disabled or step is in read only mode.
   * Otherwise emits the question ID, question value and the event.
   * @returns { void }
   */
  change(value: T, files?: File[], invalidFiles?: FileUploadDetails[]) {
    if (!this.question.enabled || this.readOnly) {
      return;
    }
    this.valueChanged.emit(
      new QuestionEvent(
        this.question.id,
        RuleTriggerEvent.QuestionUpdated,
        value,
        files,
        invalidFiles,
      ),
    );
  }
}
