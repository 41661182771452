import { Component, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FFInputNumberComponent } from '../ff-input-number/ff-input-number.component';

@Component({
  selector: 'ff-input-number-slider',
  templateUrl: './ff-input-number-slider.component.html',
})
export class FFInputNumberSliderComponent
  extends FFInputNumberComponent
  implements OnInit
{
  @Input() median?: number;

  constructor(public override control: NgControl) {
    super(control);
  }

  override ngOnInit() {
    super.ngOnInit();

    if (!this.formControl.value) {
      this.formControl.patchValue(this.minimum);
    }

    if (this.maximum != null && this.minimum != null) {
      let range = this.maximum - this.minimum;
      this.median = range / 2 + this.minimum;
    }
  }

  sliderChange($event: Event): void {
    const targetValue = Number(($event.target as HTMLInputElement).value);
    this.change(targetValue);
  }

  change(targetValue: number): void {
    let newValue: number | string = targetValue;
    if (this.maximum != null && this.minimum != null) {
      newValue = Math.min(Math.max(targetValue, this.minimum), this.maximum);
    }

    newValue = newValue.toFixed(this.decimalPlaces);
    this.formControl.setValue(newValue);
    this.OnChange.emit(newValue);
  }
}
