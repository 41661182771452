import { Component, Input } from '@angular/core';
import { QuestionBaseComponent } from '../question-base-component';
import { KeyValuePair, Lookup } from 'src/app/models/Lookup';
import { LookupService } from 'src/app/common/services/lookup/lookup.service';
import { take } from 'rxjs';

@Component({
  selector: 'lookup',
  templateUrl: './lookup.component.html',
})
export class LookupComponent extends QuestionBaseComponent<string> {
  lookup!: Lookup;
  isOptionsLoaded: boolean = false;
  options?: KeyValuePair[];

  @Input() tableId?: string;

  constructor(private _lookupService: LookupService) {
    super();
  }

  ngOnInit(): void {
    this.lookup = this.question as Lookup;
  }

  retrieveOptions() {
    //Get Lookup options once on loading component.
    if (!this.isOptionsLoaded) {
      this._lookupService
        .GetLookupQuestionOptions(this.flowId, this.lookup.id, this.tableId)
        .pipe(take(1))
        .subscribe((options) => {
          this.options = options;
        });
      this.isOptionsLoaded = true;
    }
  }
}
