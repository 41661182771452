import { Component, Input } from '@angular/core';
import { FFInputComponentBase } from '../ff-base/ff-input-component-base';

@Component({
  selector: 'ff-hyperlink',
  templateUrl: './ff-hyperlink.component.html',
  styleUrls: ['./ff-hyperlink.component.scss'],
})
export class FFHyperlinkComponent extends FFInputComponentBase {
  @Input() hyperlinkLabel?: string;
  @Input() isInputReadOnly?: boolean;
}
