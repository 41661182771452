<!-- from https://primeng.org/multiselect#api.multiselect.templates.selectedItems -->
<div>
  <div class="mb-2">
    <ff-label
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
      [showTitle]="showTitle"
      [controlId]="id"
    />
    <ng-content />
  </div>

  <ff-subtitle *ngIf="showSubtitle" [subtitle]="subtitle" />

  <ff-control-validation
    *ngIf="hasErrors && showErrorText"
    [errors]="control.errors"
    [label]="label"
  />

  <div>
    <p-multiSelect
      [options]="options!"
      [formControl]="formControl"
      [inputId]="id!"
      [showHeader]="false"
      (onChange)="onValueChangeEvent($event)"
      (onPanelHide)="onHide()"
      [class.ng-invalid]="hasErrors"
      [class.ng-dirty]="hasErrors"
      styleClass="ffx-multiselect"
      [maxSelectedLabels]="100"
      appendTo="body"
    >
      <ng-template let-value pTemplate="selectedItems">
        <div *ngIf="value && value.length > 0" class="d-flex gap-1 flex-wrap">
          <div
            class="d-flex flex-row custom-chip ps-1 border rounded-1"
            *ngFor="let option of value"
          >
            <div class="d-flex flex-row-reverse align-items-center">
              <div
                *ngIf="
                  visualType !== VisualType.Colors ||
                  visualMode === VisualMode.Text ||
                  visualMode === VisualMode.VisualText
                "
                [ngStyle]="{
                  color:
                    visualType === VisualType.Colors &&
                    visualMode === VisualMode.Text
                      ? getMappingVisualValue(option)
                      : ''
                }"
                class="ps-1"
              >
                {{ option }}
              </div>
              <div
                *ngIf="
                  visualType === VisualType.Colors &&
                  (visualMode === VisualMode.Visual ||
                    visualMode === VisualMode.VisualText)
                "
                class="shapeSize"
                [ngClass]="{
                  square: shapeType === ShapeType.Square,
                  circle: shapeType === ShapeType.Circle
                }"
                [class.rounded-circle]="shapeType === ShapeType.Circle"
                [style.backgroundColor]="getMappingVisualValue(option)"
              ></div>
            </div>
            <i (click)="removeValue($event, option)" class="bi bi-x"></i>
          </div>
        </div>
        <div
          *ngIf="!value || value.length === 0"
          class="text-muted"
          [class.visible]="placeHolder"
        >
          {{ placeHolder ?? "invisiblePlaceholder" }}
        </div>
      </ng-template>
      <ng-template let-option pTemplate="item">
        <div
          class="d-flex align-items-center gap-2"
          [ngClass]="{
            'flex-row-reverse': visualType === VisualType.Colors
          }"
        >
          <div
            *ngIf="
              visualType !== VisualType.Colors ||
              visualMode === VisualMode.Text ||
              visualMode === VisualMode.VisualText
            "
            [ngStyle]="{
              color:
                visualType === VisualType.Colors &&
                visualMode === VisualMode.Text
                  ? getMappingVisualValue(option)
                  : ''
            }"
          >
            {{ option }}
          </div>
          <div
            *ngIf="
              visualType === VisualType.Colors &&
              (visualMode === VisualMode.Visual ||
                visualMode === VisualMode.VisualText)
            "
            class="shapeSize"
            [ngClass]="{
              square: shapeType === ShapeType.Square,
              circle: shapeType === ShapeType.Circle
            }"
            [class.rounded-circle]="shapeType === ShapeType.Circle"
            [style.backgroundColor]="getMappingVisualValue(option)"
          ></div>
        </div>
      </ng-template>
    </p-multiSelect>
  </div>
</div>

<!--
  
-->
