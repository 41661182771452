import { Component, Input, OnInit } from '@angular/core';
import { NumberQuestion } from '../../../models/NumberQuestion';
import { QuestionBaseComponent } from '../question-base-component';
import { CurrencySymbolPipe } from 'src/app/visual-components/Pipes/currency-symbol.pipe';
import { FormatType } from 'src/app/models/FormatType';
import { DecimalPipe } from '@angular/common';
import { FileUploadDetails } from 'src/app/models/FileUpload';

@Component({
  selector: 'number',
  templateUrl: './number.component.html',
  providers: [DecimalPipe],
})
export class NumberComponent
  extends QuestionBaseComponent<number>
  implements OnInit
{
  constructor(
    private currencySymbolPipe: CurrencySymbolPipe,
    private decimalPipe: DecimalPipe,
  ) {
    super();
  }

  @Input() isCalculatedQuestion?: boolean = false;
  number!: NumberQuestion;

  ngOnInit(): void {
    this.number = this.question as NumberQuestion;
  }

  get getReadOnlyString(): string {
    if (this.number.value == null) return '';
    let value = this.number.value.toString();

    if (this.number.disableThousandGrouping === false) {
      const decimalPlaces = this.number.decimalPlaces ?? 0;
      value = this.decimalPipe.transform(
        this.number.value,
        `1.${decimalPlaces}-${decimalPlaces}`,
      )!;
    }

    if (this.number.format == FormatType.Percentage) {
      return value + '%';
    }

    if (this.number.format == FormatType.Currency && this.number.culture) {
      const symbol =
        this.currencySymbolPipe.transform(this.number.culture) ?? '';
      return symbol + '' + value;
    }

    return value;
  }

  override change(
    value: number,
    files?: File[],
    invalidFiles?: FileUploadDetails[],
  ) {
    this.form.get(this.number.id)?.patchValue(value);
    super.change(value, files, invalidFiles);
  }
}
