import { Pipe } from '@angular/core';
import { BaseFFxDatePipe } from './base-ffx-date.pipe';

@Pipe({
  name: 'shortDateTimePipe',
})
export class ShortDateTimePipe extends BaseFFxDatePipe {
  override get formatString(): string {
    return 'short';
  }
}
