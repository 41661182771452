import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import {
  PatchFlowTableEventResponse,
  RuleTriggerEvent,
} from '../../../common/services/flows/flow.service';
import { IStep } from '../../../models/IStep';
import { ViewportScroller } from '@angular/common';
import { FFModalService } from '../../../visual-components/services/ff-modal.service';
import {
  ButtonSize,
  ButtonType,
} from '../../../visual-components/components/ff-button/ff-button.component';
import { User } from '../../../models/User';
import { NoteAction, NoteButtonClicked } from '../add-note/add-note.component';
import { Note } from '../../../models/Note';
import { AlertType } from '../../../visual-components/components/ff-message-divider/ff-message-divider.component';
import { StepAttachmentAction } from '../../../models/Attachment';
import { FileService } from '../../../common/services/file.service';
import { QuestionType } from '../../../models/QuestionType';
import { FileUploadDetails } from '../../../models/FileUpload';
import {
  FFSpinnerService,
  FFValidationSummaryComponent,
} from '@flowforma/ff-components';
import { BaseQuestion } from 'src/app/models/Question';
import { filter, first, tap } from 'rxjs';

@Component({
  selector: 'step-detail',
  templateUrl: './step-detail.component.html',
})
export class StepDetailComponent implements OnInit {
  @ViewChild(FFValidationSummaryComponent)
  ffValidationSummaryComponent?: FFValidationSummaryComponent;

  @Input()
  step!: IStep;

  @Input()
  stepNumber!: number;

  @Input()
  form!: FormGroup;

  @Input() flowId!: string;

  @Input() flowCompleted!: boolean;

  @Input()
  lastStep: boolean = false;

  @Input() reopenMode!: boolean;

  @Input() showFormCancel!: boolean;

  @Input() currentTime!: Date;
  @Input() logo!: string;
  @Input() isSideStepperExpanded: boolean = false;

  @Output()
  stepEvent = new EventEmitter<StepEvent>();

  @Output()
  questionEvent = new EventEmitter<QuestionEvent>();

  @Output()
  tableEvent = new EventEmitter<PatchFlowTableEventResponse>();

  @Output()
  continueEvent = new EventEmitter<void>();

  @Output()
  addFeedbackEvent = new EventEmitter<AddFeedbackEvent>();

  @Output()
  stepNoteEvent = new EventEmitter<Note>();

  @Output()
  reopenStepEvent = new EventEmitter<boolean>();

  @Output()
  stepAttachmentDelete: EventEmitter<StepAttachmentEvent> =
    new EventEmitter<StepAttachmentEvent>();

  @Output()
  formCancelEvent = new EventEmitter<FormCancelEvent>();

  static readonly dataValidationErrorKey = 'customError';

  isAddNoteButtonActive: boolean = true;
  isAddNoteCommentActive: boolean = false;
  buttonTypeEnum: typeof ButtonType = ButtonType;
  buttonSizeEnum: typeof ButtonSize = ButtonSize;
  alertTypeEnum: typeof AlertType = AlertType;
  ruleTriggerEvent: typeof RuleTriggerEvent = RuleTriggerEvent;
  showQAButtons: boolean = false;
  showValidationSummary: boolean = false;

  constructor(
    private _scroller: ViewportScroller,
    private _modalService: FFModalService,
    private _fileService: FileService,
    private _spinnerService: FFSpinnerService,
  ) {}

  /**
   * @description Checks if step is completed
   * @returns { boolean } True if step is completed
   */
  public get isStepCompleted(): boolean {
    return !this.step.isActive && !this.step.isReOpened && !this.reopenMode;
  }

  ngOnInit(): void {
    // For the moment we are hiding the test buttons by default unless turned on in dev tools
    const localStorageQAValue = localStorage.getItem('showQAButtons');
    this.showQAButtons = localStorageQAValue
      ? JSON.parse(localStorageQAValue)
      : false;
  }

  /**
   * @description When the step changes a new form group is created,
   *              this allows the validation summary to resubscribe to it.
   *              Validation summary is hidden when the form group is updated.
   * @param { FormGroup } formGroup the form group for the currently selected step
   * @returns { void }
   */
  updateFormGroupForValidationSummary(formGroup: FormGroup): void {
    if (this.ffValidationSummaryComponent) {
      this.ffValidationSummaryComponent.updateFormGroup(formGroup);
      this.showValidationSummary = false;
    }
  }

  onQuestionEvent(questionEvent: QuestionEvent) {
    this.questionEvent.emit(questionEvent);
  }

  onTableEvent(tableEvent: PatchFlowTableEventResponse): void {
    this.tableEvent.emit(tableEvent);
  }
  /**
   * @description Makes sure no requests are curretly being made before function is executed
   * @param func The function to execute after the http request is complete
   * @returns { void }
   */
  buttonExecutionAfterHttpReq(func: () => void): void {
    this._spinnerService.loading$
      .pipe(
        filter((loading) => !loading),
        first(), // Take the first occurrence of loading being false
        tap(() => {
          // Execute after loading is complete
          func();
        }),
      )
      .subscribe();
  }

  /**
   * @description Checks if page has valid form, if it does open confirmation dialog to submit step
   * @param { string } stepId The step Id
   * @param { RuleTriggerEvent } ruleTriggerEvent The even that triggered the change
   * @returns { void }
   */
  triggerChange(stepId: string, ruleTriggerEvent: RuleTriggerEvent): void {
    this.buttonExecutionAfterHttpReq(() => {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      if (this.controlsAreValidOnFormGroup(this.form)) {
        this._modalService.openConfirmationDialog(
          'Ready to submit?',
          "Are you sure that you're ready to submit? You will not be able to edit your data after submitting, you will only be able to review it.",
          () => this.stepEvent.emit(new StepEvent(stepId, ruleTriggerEvent)),
        );
      } else {
        this.showValidationSummary = true; // Show validation summary only when the validation fails
        // Allow page to make validation summary visible before scrolling
        setTimeout(() => {
          this._scroller.scrollToPosition([0, 0]);
        });
      }
    });
  }

  /**
   * @description Emits event from continue button click to go to next step when in read only mode
   * @returns { void }
   */
  continueBtnClick(): void {
    this.continueEvent.emit();
  }

  reopenStep(): void {
    const user: User = {
      imgUri: '', // Image should be updated with or after #19291 is complete
      name: this.step.assignedTo!,
    };

    this._modalService.openStepReopenModal(
      this.stepNumber,
      this.step.title,
      this.step.id!,
      user,
      (dueDate: Date) => {
        if (dueDate) {
          this.addFeedbackEvent.emit({
            dueDate: dueDate,
            returnTo: user,
          } as AddFeedbackEvent);
        }
      },
    );
  }

  addNotes(): void {
    this.isAddNoteButtonActive = false;
    this.isAddNoteCommentActive = true;
  }

  updateNoteAction(noteAction: NoteAction): void {
    this.isAddNoteButtonActive = true;
    this.isAddNoteCommentActive = false;

    if (
      noteAction.noteButtonClicked === NoteButtonClicked.Save &&
      noteAction.note &&
      noteAction.createdDate
    )
      this.stepNoteEvent.emit({
        id: undefined,
        text: noteAction.note,
        createdDate: noteAction.createdDate,
      });
  }

  confirmReopen(): void {
    this._modalService.openConfirmationDialog(
      'Re-open step',
      'Reopening a step, opens all following steps to ensure changes are applied across the process. Are you ready to reopen this step?',
      () => this.reopenStepEvent.emit(true),
    );
  }

  cancelReopen(): void {
    this._modalService.openConfirmationDialog(
      'Cancel reopening?',
      "This will close this step and not save any comments or issues you've raised.",
      () => this.reopenStepEvent.emit(false),
    );
  }

  canShowReopen(): boolean {
    if (this.flowCompleted) return false;
    if (this.step.isFutureStep) return false;
    if (this.reopenMode) return false;
    if (!this.step.enableReopen) return false;
    if (this.step.isActive) return false;

    return true;
  }

  canShowAddNotes(): boolean {
    if (
      !this.step.isActive &&
      this.isAddNoteButtonActive &&
      !this.flowCompleted
    )
      return true;

    return false;
  }

  anchorToQuestion(questionId: string): void {
    this._scroller.scrollToAnchor(questionId);
  }

  deleteAttachment(attachmentId: string): void {
    this.stepAttachmentDelete.emit(
      new StepAttachmentEvent(
        StepAttachmentAction.Delete,
        this.step.id!,
        attachmentId,
        undefined,
      ),
    );
  }

  get showAttachments(): boolean {
    if (this.step.attachments?.length && !this.flowCompleted) return true;
    return false;
  }

  /**
   * @description Checks if step valid form excluding error messages from data validation
   * @param { FormGroup<any> } formGroup The formgroup to check if controls are valid
   * @returns { boolean } Whether form is valid for submitting
   */
  controlsAreValidOnFormGroup(formGroup: FormGroup<any>): boolean {
    let formValidExcludingCustErrors = true;
    Object.keys(formGroup.controls).forEach((formControlName) => {
      const formControlErrors = formGroup.get(formControlName)?.errors;
      if (!StepDetailComponent.onlyDataValidationErrors(formControlErrors)) {
        formValidExcludingCustErrors = false;
      }
    });
    return formValidExcludingCustErrors;
  }

  /**
   * @static
   * @description Checks if the error messages has only error messages with key 'customError'
   * @param { ValidationErrors | null | undefined } errors The errors for the form control
   * @returns { boolean } Whether the control is valid
   */
  static onlyDataValidationErrors(
    errors: ValidationErrors | null | undefined,
  ): boolean {
    let validControl = true;
    if (!errors) {
      return validControl;
    }
    Object.keys(errors).forEach((errorKey) => {
      if (errorKey !== this.dataValidationErrorKey) validControl = false;
    });
    return validControl;
  }

  cancelForm(): void {
    this._modalService.openConfirmationDialog(
      'Cancel form confirmation',
      'Are you sure you want to cancel the form?',
      () => {
        // Delete Step Attachments
        if (this.step.attachments && this.step.attachments.length > 0) {
          for (let attachment of this.step.attachments) {
            this._fileService
              .deleteListItemFile(
                attachment.storageType,
                attachment.relativeFilePath!,
              )
              .subscribe();
          }
        }

        // Filter Step File Upload Questions
        let fileUploadQuestions = this.filterFileUploadQuestions();

        // Delete FileUpload Questions files
        for (let question of fileUploadQuestions) {
          const attachmentFile = this.form.get(question.id)!
            .value as FileUploadDetails[];
          for (let file of attachmentFile) {
            this._fileService
              .deleteListItemFile(file.storageType!, file.relativeFilePath!)
              .subscribe({
                error: () => {
                  // Handle error
                },
              });
          }
        }

        this.formCancelEvent.emit(new FormCancelEvent(this.flowId));
      },
    );
  }

  filterFileUploadQuestions(): BaseQuestion[] {
    return this.step.questions?.filter((q) => {
      if (q.questionType === QuestionType.FileUpload) {
        const control = this.form.get(q.id);

        return (
          control?.value !== null &&
          control?.value !== undefined &&
          control?.value !== ''
        );
      }
      return false;
    });
  }

  get checkStepOverdue(): boolean {
    if (this.step.dueDate) {
      return new Date(this.step.dueDate) < this.currentTime;
    }
    return false;
  }

  get canShowContinue(): boolean {
    return (!this.step.enabled || !this.step.isActive) && !this.lastStep;
  }

  get canShowStepSubmit(): boolean {
    return this.step.enabled && this.step.isActive && !this.step.isReadOnly;
  }

  get canShowStepNavigationButtons(): boolean {
    return this.reopenMode
      ? false
      : this.canShowContinue || this.canShowStepSubmit || this.showFormCancel;
  }
}

export class QuestionEvent {
  /**
   *
   */
  constructor(
    public questionId: string,
    public ruleTriggerEvent: RuleTriggerEvent,
    public newValue?: any,
    public files?: File[],
    public invalidFileUploadDetails?: FileUploadDetails[],
  ) {}
}

export class StepEvent {
  /**
   *
   */
  constructor(
    public stepId: string,
    public ruleTriggerEvent: RuleTriggerEvent,
    public files?: File[],
  ) {}
}

export class AddFeedbackEvent {
  constructor(
    public dueDate: Date,
    public returnTo: User,
  ) {}
}

export class StepAttachmentEvent {
  constructor(
    public eventType: StepAttachmentAction,
    public stepId: string,
    public attachmentId?: string,
    public files?: File[],
  ) {}
}

export class FormCancelEvent {
  constructor(public formId: string) {}
}
