import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FeedbackPanel } from './feedback-panel.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateOnlyInputPipe } from 'src/app/common/pipes/ffx-date-pipes/date-only-input.pipe';

@Component({
  selector: 'feedback-panel',
  templateUrl: './feedback-panel.component.html',
})
export class FeedbackPanelComponent {
  constructor(protected dateOnlyInputPipe: DateOnlyInputPipe) {}

  @Input() data?: FeedbackPanel;
  isEditDueDateEnabled: boolean = false;

  @Output()
  changeDueDateEvent = new EventEmitter<Date>();

  public form = new FormGroup({
    dateSelect: new FormControl('', Validators.required),
  });

  editDueDate(): void {
    this.isEditDueDateEnabled = true;
    this.controlsReset();
  }

  dueDateChange(event: string): void {
    if (!this.isEditDueDateEnabled) return;

    const dateTransform = this.dateOnlyInputPipe.transform(event)!;

    this.form.controls.dateSelect.setValue(dateTransform);

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const convertedDate = new Date(event);
    this.changeDueDateEvent.emit(convertedDate);

    this.isEditDueDateEnabled = false;
  }

  cancelEdit(): void {
    this.isEditDueDateEnabled = false;
  }

  private controlsReset(): void {
    this.form.controls.dateSelect.reset();
    this.form.controls.dateSelect.updateValueAndValidity();
  }
}
