import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUploadDetails } from 'src/app/models/FileUpload';

@Component({
  selector: 'ff-upload-list-images',
  templateUrl: './ff-upload-list-images.component.html',
})
export class FFUploadListImagesComponent {
  @Input() files: ListImageItem[] = [];
  @Input() savedfiles: FileUploadDetails[] = [];
  @Input() readOnly = false;

  @Output() downloadFile: EventEmitter<number> = new EventEmitter<number>();
  @Output() onDelete: EventEmitter<number> = new EventEmitter<number>();

  /**
   * @description Emits event to download file at given index
   * @param index the index of the item to download
   */
  downloadFileItem(index: number) {
    if (this.files.length > index && index >= 0) {
      this.downloadFile.emit(index);
    }
  }

  /**
   * @description Deletes the list item at the given index
   * @param index the index of the item to delete
   */
  deleteListImageItem(index: number) {
    if (this.files.length > index && index >= 0) {
      this.onDelete.emit(index);
    }
  }
}

export interface ListImageItem {
  fileName: string;
  image: File | string;
}
