import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Table } from 'primeng/table';
import { OutputData, TableFeaturesService } from './table-features.service';
import { QuestionType } from 'src/app/models/QuestionType';
import { TableViewBase } from '../table-view-base/table-view-base.component';

export const keywordSearchKey = '_keywordSearch';

@Component({
  selector: 'table-features',
  templateUrl: './table-features.component.html',
  styleUrls: ['./table-features.component.scss'],
})
export class TableFeaturesComponent extends TableViewBase implements OnInit {
  @ViewChild('dt') table: Table | undefined;

  @Input() viewableRows: number = 5;
  @Input() firstViewingItemIndex: number = 0;

  @Output() viewingData = new EventEmitter<Output[]>();

  questionType: typeof QuestionType = QuestionType;
  currentPageData: Output[] = [];
  sortField: string | null = null;
  sortOrder: number | null = null;
  keywordSearch: string = '';

  constructor(private tableFeaturesService: TableFeaturesService) {
    super();
  }

  ngAfterViewInit(): void {
    this.checkStateStorage();
  }

  ngOnInit(): void {
    this.keywordSearch =
      sessionStorage.getItem(this.question.id + keywordSearchKey) ?? '';

    this.viewingData.emit(this.getCurrentPageData(this.tableData.cellData));
  }

  checkStateStorage(): void {
    const tableStateJson = sessionStorage.getItem(this.question.id);
    if (tableStateJson) {
      const tableState = JSON.parse(tableStateJson);
      this.firstViewingItemIndex = tableState.first;
      this.viewableRows = tableState.rows;
      this.sortField = tableState.sortField;
      this.sortOrder = tableState.sortOrder;
      let tableCellData = [...this.tableData.cellData];
      if (this.table?.filteredValue) {
        tableCellData = this.table.filteredValue;
      }
      this.currentPageData = this.getCurrentPageData(tableCellData);
      this.viewingData.emit(this.currentPageData);
    }
  }

  tablePage(event: any): void {
    const tableCellData = [...this.tableData.cellData];
    this.viewableRows = event.rows;
    this.firstViewingItemIndex = event.first;
    this.currentPageData = this.getCurrentPageData(tableCellData);
    this.viewingData.emit(this.currentPageData);
  }

  tableSort(event: any, sorted: boolean = true): void {
    let tableCellData = [...this.tableData.cellData];
    this.sortField = event.field;
    this.sortOrder = event.order;
    if (!sorted && this.sortField && this.sortOrder) {
      tableCellData = this.tableFeaturesService.sortSingle(
        this.sortField,
        this.sortOrder,
        tableCellData,
      );
    }
    this.currentPageData = this.getCurrentPageData(tableCellData);
    this.viewingData.emit(this.currentPageData);
  }

  onFilter(event: any): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement && this.table) {
      sessionStorage.setItem(
        this.question.id + keywordSearchKey,
        inputElement.value,
      );
      this.table.filterGlobal(inputElement.value, 'contains');
    }
  }

  // Used to keep correct page after updating table value
  // More context in https://github.com/primefaces/primeng/issues/11898#issuecomment-1605714377
  firstIndexChange(event: any): void {
    if (this.table) {
      this.updatePage(this.firstViewingItemIndex);
    }
  }

  updatePage(firstItemIndex: number): void {
    if (this.table) {
      this.table.first = firstItemIndex;
    }
  }

  tableFilter(event: any): void {
    this.currentPageData = this.getCurrentPageData(event.filteredValue);
    this.viewingData.emit(this.currentPageData);
  }

  private getCurrentPageData(tableCellData: OutputData[]): any {
    let endViewingItemIndex = this.firstViewingItemIndex + this.viewableRows;

    if (this.table?.filteredValue) {
      endViewingItemIndex = Math.min(
        this.firstViewingItemIndex + this.viewableRows,
        this.table.filteredValue.length,
      );
      if (this.firstViewingItemIndex > endViewingItemIndex) {
        this.firstViewingItemIndex = 0;
        endViewingItemIndex = this.viewableRows;
        this.updatePage(this.firstViewingItemIndex);
      }
    }

    const currentPageData = tableCellData.slice(
      this.firstViewingItemIndex,
      endViewingItemIndex,
    );

    return currentPageData;
  }
}
