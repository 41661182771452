import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FFComponentBase } from '../ff-base/ff-component.base';

@Component({
  selector: 'ffx-button',
  templateUrl: './ff-button.component.html',
  styleUrls: ['./ff-button.component.scss'],
})
export class FFButtonComponent extends FFComponentBase {
  @Input() textValue?: string;
  @Input() invalid = false;
  @Input() buttonType: ButtonType = ButtonType.Primary;
  @Input() buttonSize: ButtonSize = ButtonSize.Large;
  @Input() buttonTooltip: boolean = true;
  @Input() type: string = 'button';
  @Output() OnClick = new EventEmitter<void>();

  // Constructor
  constructor() {
    super();
  }

  /**
   * @description Function that is triggered when the button is clicked.
   * @returns { void }
   */
  public onClickEvent(): void {
    this.OnClick.emit();
  }

  get showQuestionInfo(): boolean {
    return (
      (!!this.label && this.showTitle) ||
      !!this.tooltip ||
      this.required ||
      this.showCommentIcon
    );
  }
}

export enum ButtonType {
  Primary = 'btn-primary',
  Secondary = 'btn-outline-primary',
  Danger = 'btn-outline-danger',
  Warning = 'btn-warning',
  Note = 'btn-outline-primary pe-3 pe-sm-2',
}

export enum ButtonSize {
  Small = 'btn-sm',
  Medium = 'btn-md',
  Large = 'btn-lg',
}
