import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, take } from 'rxjs';

import {
  FlowService,
  FormReopenRequest,
  PatchFlowEventResponse,
  PatchFlowRequest,
  PatchFlowTableEventResponse,
  RuleTriggerEvent,
  StepAttachmentUploadRequest,
  StepFeedbackRequest,
  StepNoteRequest,
  StepReopenRequest,
  SubmitQuestionRequest,
  SubmitStepRequest,
  TableOperationType,
} from '../../../common/services/flows/flow.service';

import { IStep } from '../../../models/IStep';
import { IFlow } from '../../../models/IFlow';
import { Section } from '../../../models/Section';
import { BaseQuestion, Question } from '../../../models/Question';
import {
  AddFeedbackEvent,
  FormCancelEvent,
  QuestionEvent,
  StepAttachmentEvent,
  StepDetailComponent,
  StepEvent,
} from '../../steps/step-detail/step-detail.component';
import { QuestionType } from '../../../models/QuestionType';
import { FFModalService } from '../../../visual-components/services/ff-modal.service';
import {
  TableAnswer,
  TableColumn,
  TableQuestion,
} from '../../../models/TableQuestion';
import { StepSubmittedAction } from '../../steps/step-submitted/step-submitted.component';
import { URIService } from '../../../common/services/uri.service';
import { Feedback } from '../../../models/Feedback';
import { FeedbackPanel } from '../feedback-panel/feedback-panel.model';
import { FlowEvent } from '../../../models/FlowEvent';
import {
  CreateCommentThreadDto,
  ArchivedComments,
} from '../../questions/question-comment/question-comment.model';
import { Note } from '../../../models/Note';
import { FormReopeningData } from '../form-reopening-confirmation-dialog/form-reopening-confirmation-dialog.component';
import { StepAttachmentAction } from '../../../models/Attachment';
import {
  ButtonSize,
  ButtonType,
} from '../../../visual-components/components/ff-button/ff-button.component';
import { ScrollLockService } from '../../../common/services/scroll-lock.service';
import { RelativeTimePipe } from '../../../common/pipes/relative-time.pipe';
import {
  FileUpload,
  FileUploadDetails,
  FileUploadStorageType,
} from '../../../models/FileUpload';
import { FormHistory } from '../../../models/FormHistory';
import {
  AffectedQuestion,
  AffectedQuestionAction,
} from '../../../models/AffectedQuestion';
import { FullDatePipe } from 'src/app/common/pipes/ffx-date-pipes/full-date.pipe';
import { FileService } from 'src/app/common/services/file.service';
import { Choice } from 'src/app/models/Choice';
import { FormActionType, OpenFileInNewTab } from 'src/app/models/FormActions';
import { TableFeaturesService } from '../../questions/table/table-features/table-features.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  @ViewChild(StepDetailComponent) stepDetailComponent?: StepDetailComponent;
  @Input() form!: FormGroup;

  flow$?: Observable<IFlow>;
  flowEvents: FlowEvent[] = [];
  allStepsTitles: Map<string, string> = new Map<string, string>();
  selectedStep?: IStep;
  formId?: number;
  flowId?: number;
  formGuid?: string;
  ruleTriggerEvent: typeof RuleTriggerEvent = RuleTriggerEvent;
  questionType: typeof QuestionType = QuestionType;
  showQAButtons: boolean = false;
  lastStep: boolean = false;
  stepIndex: number = 0;
  flow?: IFlow;
  feedbackPanelData?: FeedbackPanel;
  isSummarySelected: boolean = false;
  isWorkflowHistorySelected: boolean = false;
  reopenMode: boolean = false;
  isSideStepperExpanded: boolean = false;
  isMinWidthLg: boolean = false;
  currentShowComponentEnum: AvailableComponent = AvailableComponent.StepDetail;
  availableComponentEnum = AvailableComponent;
  buttonTypeEnum: typeof ButtonType = ButtonType;
  buttonSizeEnum: typeof ButtonSize = ButtonSize;
  archivedQuestionComments: (ArchivedComments | undefined)[] = [];
  isAffectedQuestionsWithErrors: boolean = false;
  currentTime: Date = new Date();
  currentTimeUpdateInterval?: NodeJS.Timeout;
  defaultLogoImage: string = 'assets/default_logo.png';
  poweredByLogo: string = 'assets/powered_by_logo.png';
  constructor(
    private _flowService: FlowService,
    private _route: ActivatedRoute,
    private _modalService: FFModalService,
    private _uriService: URIService,
    private _scrollLockService: ScrollLockService,
    private _fullDatePipe: FullDatePipe,
    private _fileService: FileService,
    private _tableFeaturesService: TableFeaturesService,
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.initializeQAButtons();
    this.subscribeToFlowUpdates();
  }

  initializeForm(): void {
    const flowIdParam = this._route.snapshot.queryParamMap.get('flowId');
    const formIdParam = this._route.snapshot.queryParamMap.get('formId');
    const isNewParam = this._route.snapshot.queryParamMap.get('isNew');

    this.flowId = flowIdParam ? Number(flowIdParam) : undefined;
    this.formId = formIdParam ? Number(formIdParam) : undefined;
    const isNew: boolean = isNewParam === 'true';

    this.flow$ = this._flowService.get(this.flowId!, this.formId!, isNew);
  }

  initializeQAButtons(): void {
    const localStorageQAValue = localStorage.getItem('showQAButtons');
    this.showQAButtons = localStorageQAValue
      ? JSON.parse(localStorageQAValue)
      : false;
  }

  subscribeToFlowUpdates(): void {
    this.flow$?.subscribe((flow: IFlow) => {
      this.updateFlow(flow);
    });
  }

  /**
   * @description Exists the form, calls save if form isn't complete
   * @returns { void }
   */
  public saveAndExit(): void {
    if (this.flow?.isFlowCompleted) {
      this._modalService.openConfirmationDialog(
        'Save and exit confirmation',
        'Are you sure you want to want to save and exit the form?',
        () => {
          this._uriService.redirectToFlowFormaFormsList();
        },
      );
    } else {
      this.saveAndExitNotCompletedFlow();
    }
  }

  saveAndExitNotCompletedFlow(): void {
    if (this.reopenMode) this.exitInReopenMode();
    else if (this.selectedStep?.isActive && !this.selectedStep?.isReadOnly)
      this.saveAndExitActiveStep();
    else this.exitCompletedStep();
  }

  saveAndExitActiveStep(): void {
    if (this.selectedStep?.dueDate) {
      if (new Date(this.selectedStep.dueDate) < this.currentTime) {
        this._modalService.openConfirmationDialog(
          'Close step?',
          'Closing now will save your progress. Remember, your step(s) were due ' +
            new RelativeTimePipe().transform(
              this.selectedStep.dueDate,
              this.currentTime,
              'ago',
            ) +
            ', <span class="fw-bold">' +
            this._fullDatePipe.transform(this.selectedStep.dueDate) +
            '</span>.',
          () => {
            this.saveStepOnExit();
            this._uriService.redirectToFlowFormaFormsList();
          },
        );
      } else {
        this._modalService.openConfirmationDialog(
          'Close step?',
          'Closing now will save your progress. Remember, your step(s) are due by: <span class="fw-bold">' +
            this._fullDatePipe.transform(this.selectedStep.dueDate) +
            '</span>.',
          () => {
            this.saveStepOnExit();
            this._uriService.redirectToFlowFormaFormsList();
          },
        );
      }
    } else {
      this._modalService.openConfirmationDialog(
        'Close step?',
        'Closing now will save your progress and allow you to come back to it at a later date.',
        () => {
          this.saveStepOnExit();
          this._uriService.redirectToFlowFormaFormsList();
        },
      );
    }
  }

  exitInReopenMode(): void {
    this._modalService.openConfirmationDialog(
      'Close step?',
      'Closing now will save your progress and allow you to come back to it at a later date (In reopen).',
      () => {
        this._uriService.redirectToFlowFormaFormsList();
      },
    );
  }

  exitCompletedStep(): void {
    this._modalService.openConfirmationDialog(
      'Close step?',
      'Closing now will save your progress and allow you to come back to it at a later date.',
      () => {
        this._uriService.redirectToFlowFormaFormsList();
      },
    );
  }

  saveStepOnExit(): void {
    this._flowService
      .submitStep(
        new SubmitStepRequest(
          this.formGuid!,
          this.selectedStep!.id!,
          RuleTriggerEvent.StepSaving,
        ),
      )
      .subscribe((response: PatchFlowEventResponse) => {});
  }

  private loadFormGroup(): void {
    const group: CustomFormControl = {};
    this.addAllQuestionsFormControls(this.selectedStep?.questions, group);
    this.form = new FormGroup(group);
  }

  private addAllQuestionsFormControls(
    questions: BaseQuestion[] | undefined,
    group: CustomFormControl,
  ): void {
    questions?.forEach((question) => {
      switch (question.questionType) {
        case this.questionType.Section:
          this.addSectionQuestionFormControls(group, question);
          break;
        case this.questionType.Table:
          this.addTableQuestionFormControls(group, question);
          break;
        default:
          this.addQuestionFormControl(group, question);
          break;
      }
    });
  }

  private addSectionQuestionFormControls(
    group: CustomFormControl,
    question: BaseQuestion,
  ): void {
    const section = question as Section;
    this.addAllQuestionsFormControls(section.questions, group);
  }

  private addTableQuestionFormControls(
    group: CustomFormControl,
    question: BaseQuestion,
  ): void {
    const questionTable = question as TableQuestion;
    this.addQuestionFormControl(group, questionTable);

    for (const tableColumn of questionTable.tableColumns ?? []) {
      this.addTableColumnFormControls(tableColumn, group);
    }
  }

  private addTableColumnFormControls(
    tableColumn: TableColumn,
    group: CustomFormControl,
  ): void {
    for (const tableCell of tableColumn.cells ?? []) {
      this.addTableCellFormControls(
        tableColumn.headerQuestion,
        tableCell,
        group,
      );
    }
    for (const footerQuestion of tableColumn.footerQuestions ?? []) {
      this.addQuestionFormControl(group, footerQuestion);
    }
  }

  private addTableCellFormControls(
    headerQuestion: Question<void>,
    tableCell: TableAnswer,
    group: CustomFormControl,
  ): void {
    const mappedQuestion: Question<typeof tableCell.value> = {
      ...headerQuestion,
      id: tableCell?.id,
      value: tableCell?.value,
    };
    mappedQuestion.enabled = tableCell.enabled;
    mappedQuestion.visible = tableCell.visible;

    this.addQuestionFormControl(group, mappedQuestion);
  }

  private removeTableQuestionFormControls(tableQuestion: TableQuestion): void {
    this.removeQuestionFormControl(tableQuestion);

    for (const tableColumn of tableQuestion.tableColumns ?? []) {
      this.removeTableColumnFormControls(tableColumn);
    }
  }

  private removeTableColumnFormControls(tableColumn: TableColumn): void {
    for (const tableCell of tableColumn.cells ?? []) {
      this.removeTableCellFormControls(tableCell);
    }
    for (const footerQuestion of tableColumn.footerQuestions ?? []) {
      this.removeQuestionFormControl(footerQuestion);
    }
  }

  private removeTableCellFormControls(tableCell: TableAnswer): void {
    this.form.removeControl(tableCell.id);
  }

  /**
   * @description Updates the form group for the affected questions
   * @param { AffectedQuestion[] | undefined } affectedQuestions List of affected questions
   * @returns { void }
   */
  updateFormGroup(
    affectedQuestions: AffectedQuestion[] | undefined,
    flow: IFlow,
    tableQuestionId?: string,
  ): void {
    affectedQuestions?.forEach((affectedQuestion) => {
      if (affectedQuestion.actions) {
        affectedQuestion.actions.forEach((action) => {
          switch (action) {
            case AffectedQuestionAction.Hide:
              this.handleQuestionActionHide(affectedQuestion, flow);
              break;
            case AffectedQuestionAction.Show:
              this.handleQuestionActionShow(affectedQuestion, flow);
              break;
            case AffectedQuestionAction.Enable:
              this.handleQuestionActionState(affectedQuestion, flow, true);
              break;
            case AffectedQuestionAction.Disable:
              this.handleQuestionActionState(affectedQuestion, flow, false);
              break;
            case AffectedQuestionAction.ChoicesUpdate:
              this.handleQuestionActionChoice(affectedQuestion);
              break;
            case AffectedQuestionAction.TableUpdate:
              this.updateTableAction(affectedQuestion, flow);
              break;
            case AffectedQuestionAction.TableChoicesUpdate:
              this._tableFeaturesService.updateQuestionChoicesData(
                affectedQuestion,
              );
              break;
            case AffectedQuestionAction.DataValidationError:
              this.isAffectedQuestionsWithErrors = true;
              this.updateControlWithValidationMessage(
                affectedQuestion.questionId,
                tableQuestionId,
                affectedQuestion.errorMessage,
                affectedQuestion.rowId,
              );
              break;
            case AffectedQuestionAction.TableQuestionUpdate: {
              this._tableFeaturesService.updateCellData(affectedQuestion);
              break;
            }
            case AffectedQuestionAction.QuestionUpdate:
              this.updateFormValue(
                affectedQuestion.questionId,
                affectedQuestion.newValue,
              );
              break;
          }
        });
      }
    });
  }

  /**
   * @description Handles the hide action for a question,
   * removes the form control and question from the selected step.
   * @param { AffectedQuestion } affectedQuestion Affected question
   * @returns { void }
   */
  private handleQuestionActionHide(
    affectedQuestion: AffectedQuestion,
    flow: IFlow,
  ): void {
    if (affectedQuestion.rowId) {
      const newSelectedStep = flow.steps.find(
        (step) => step.id === this.selectedStep?.id,
      );
      const questionToAdd = this.handleQuestion(
        newSelectedStep?.questions,
        this.selectedStep?.questions,
        affectedQuestion,
      );
      if (questionToAdd) {
        if (questionToAdd.questionType === QuestionType.Table) {
          // Handle table cell visibility
          const tableQuestion = questionToAdd as TableQuestion;
          tableQuestion.tableColumns?.forEach((column) => {
            if (column.headerQuestion.id == affectedQuestion.questionId) {
              const cell = column.cells.find(
                (cell) => cell.rowId === affectedQuestion.rowId,
              );
              if (cell) {
                const control = this.form.get(cell.id);
                if (control) {
                  control.setValue(cell.value);
                  control.disable();
                }
              }
            }
          });
          this.updateTable(tableQuestion);
        }
      }
    } else {
      const removedQuestion = this.hideQuestion(
        this.selectedStep?.questions,
        affectedQuestion,
      );
      if (removedQuestion) {
        if (removedQuestion.questionType === QuestionType.Table) {
          this.removeTableQuestionFormControls(
            removedQuestion as TableQuestion,
          );
        } else {
          this.removeQuestionFormControl(removedQuestion);
        }
      }
    }
  }

  /**
   * @description Hides a question based on affected question  id.
   * Iterates through the questions list until find question id matching affected question id.
   * Updates the currentQuestions with question being removed.
   * @param { BaseQuestion[] } questions - An array of new questions.
   * @param { AffectedQuestion } affectedQuestion - The affected question.
   * @returns { boolean } - True if question is found and removed, false otherwise.
   */
  private hideQuestion(
    questions: BaseQuestion[] | undefined,
    affectedQuestion: AffectedQuestion,
  ): BaseQuestion | null {
    let removedQuestion: BaseQuestion | null = null;

    if (!questions) return removedQuestion;

    questions.forEach((question, index) => {
      if (question.questionType === QuestionType.Section) {
        const removedInSection = this.hideQuestion(
          (question as Section)?.questions,
          affectedQuestion,
        );
        if (removedInSection) removedQuestion = removedInSection;
      }
      if (question.id === affectedQuestion.questionId) {
        removedQuestion = questions.splice(index, 1)[0];
      }
    });

    return removedQuestion;
  }

  /**
   * @description Show a question based on affected question  id.
   * Iterates through the newQuestions list until find question id matching affected question id.
   * Updates the currentQuestions list with the new question.
   * We hide the section to show after some to force the DOM to update.
   * @param { BaseQuestion[] } newQuestions - An array of new questions.
   * @param { BaseQuestion[] } currentQuestions - An array of current questions.
   * @param  { AffectedQuestion } affectedQuestion - The affected question.
   * @returns { BaseQuestion | undefined } The question being added or undefined.
   */
  private handleQuestion(
    newQuestions: BaseQuestion[] | undefined,
    currentQuestions: BaseQuestion[] | undefined,
    affectedQuestion: AffectedQuestion,
  ): BaseQuestion | undefined {
    if (!newQuestions || !currentQuestions) return;

    let foundQuestion: BaseQuestion | undefined;

    newQuestions.forEach((question, index) => {
      if (question.questionType === QuestionType.Section) {
        const section = currentQuestions.find(
          (q) => q.id === question.id,
        ) as Section;
        section.visible = false;
        const sectionQuestion = this.handleQuestion(
          (question as Section).questions,
          section.questions,
          affectedQuestion,
        );
        setTimeout(() => {
          section.visible = true;
        });
        if (sectionQuestion) foundQuestion = sectionQuestion;
      }
      if (question.id === affectedQuestion.questionId) {
        this.addQuestionToCurrentQuestions(question, currentQuestions);
        foundQuestion = question;
      } else if (question.questionType === QuestionType.Table) {
        const tableQuestion = newQuestions[index] as TableQuestion;
        const foundHeaderQuestion = tableQuestion.tableColumns?.find(
          (column) => {
            return column.headerQuestion.id === affectedQuestion.questionId;
          },
        );
        if (foundHeaderQuestion) foundQuestion = tableQuestion;
      }
    });

    return foundQuestion;
  }

  /**
   * @description Adds the new question in correct order to the current questions list.
   * @param { BaseQuestion } newQuestion new Question to be added
   * @param { BaseQuestion[] } currentQuestions Current questions list
   * @returns { void }
   */
  addQuestionToCurrentQuestions(
    newQuestion: BaseQuestion,
    currentQuestions: BaseQuestion[],
  ): void {
    const insertIndex = currentQuestions.findIndex(
      (q) => q.index >= newQuestion.index,
    );

    if (insertIndex === -1) {
      currentQuestions.push(newQuestion);
    } else if (currentQuestions[insertIndex].id !== newQuestion.id) {
      currentQuestions.splice(insertIndex, 0, newQuestion);
    }
  }

  /**
   * @description Handles the show action for a question,
   * adds question to the selected step and the form control.
   * @param { AffectedQuestion } affectedQuestion Affected question
   * @param { IFlow } flow Flow of the form
   * @returns { void }
   */
  private handleQuestionActionShow(
    affectedQuestion: AffectedQuestion,
    flow: IFlow,
  ): void {
    const newSelectedStep = flow.steps.find(
      (step) => step.id === this.selectedStep?.id,
    );
    const questionToAdd = this.handleQuestion(
      newSelectedStep?.questions,
      this.selectedStep?.questions,
      affectedQuestion,
    );
    if (!questionToAdd) return;

    if (questionToAdd.questionType === QuestionType.Table) {
      if (questionToAdd.id === affectedQuestion.questionId) {
        const tableQuestion = questionToAdd as TableQuestion;
        this.addTableQuestionFormControls(
          this.form.controls as CustomFormControl,
          tableQuestion,
        );
        this.updateTable(tableQuestion);
      } else {
        const tableQuestion = questionToAdd as TableQuestion;
        tableQuestion.tableColumns?.forEach((column) => {
          if (
            column.headerQuestion.id == affectedQuestion.questionId &&
            affectedQuestion.rowId
          ) {
            const cell = column.cells.find(
              (cell) => cell.rowId === affectedQuestion.rowId,
            );
            if (cell) {
              const control = this.form.get(cell.id);
              if (control) {
                control.enable();
                control.setValue(cell.value);
              }
            }
          }
        });
        this.updateTable(tableQuestion);
      }
    } else if (!this.form.get(affectedQuestion.questionId)) {
      this.addQuestionFormControl(
        this.form.controls as CustomFormControl,
        questionToAdd,
      );
    }
  }

  private updateTableAction(
    affectedQuestion: AffectedQuestion,
    flow: IFlow,
  ): void {
    let currentTable = this.tableFromStep(
      this.selectedStep,
      affectedQuestion.questionId,
    );
    if (!currentTable) return;

    const responseStep = flow.steps.find(
      (step) => step.id === this.selectedStep?.id,
    );
    let responseTable = this.tableFromStep(
      responseStep,
      affectedQuestion.questionId,
    );

    if (!responseTable) return;
    // Add form control cells that don't exist in the new table
    for (const tableColumn of responseTable.tableColumns ?? []) {
      for (const tableCell of tableColumn.cells ?? []) {
        const cellExistsInNewTable = currentTable.tableColumns?.some(
          (newTableColumn) =>
            newTableColumn.cells.some(
              (newTableCell) => newTableCell.id === tableCell.id,
            ),
        );

        if (!cellExistsInNewTable) {
          this.addTableCellFormControls(
            tableColumn.headerQuestion,
            tableCell,
            this.form.controls as CustomFormControl,
          );
        }
      }
    }

    // Update table question in selected step
    this.updateTable(responseTable);
  }

  /**
   * @description Handles the action state for a question,
   * enables or disables the question and the form control.
   * @param { AffectedQuestion } affectedQuestion Affected question
   * @param { boolean } state State of the question, enabled or disabled
   * @returns { void }
   */
  private handleQuestionActionState(
    affectedQuestion: AffectedQuestion,
    flow: IFlow,
    state: boolean,
  ): void {
    if (affectedQuestion.rowId) {
      if (state) {
        this.handleQuestionActionShow(affectedQuestion, flow);
      } else {
        this.handleQuestionActionHide(affectedQuestion, flow);
      }
    } else {
      const questionToState = this.questionsFromStep(this.selectedStep).find(
        (question) => question.id === affectedQuestion.questionId,
      );
      const controlToState = this.form.get(affectedQuestion.questionId);

      if (controlToState && questionToState) {
        questionToState.enabled = state;
        state ? controlToState.enable() : controlToState.disable();
      }
    }
  }

  /**
   * @description Updates the choices for the json choice question.
   * @param { AffectedQuestion } affectedQuestion Affected question
   * @returns { void }
   */
  private handleQuestionActionChoice(affectedQuestion: AffectedQuestion): void {
    const questionToUpdate = this.questionsFromStep(this.selectedStep).find(
      (question) => question.id === affectedQuestion.questionId,
    );
    if (questionToUpdate) {
      // To get DOM to update, we hide the question and show it after some time.
      questionToUpdate.visible = false;
      (questionToUpdate as Choice).choices = affectedQuestion.newValue;
      setTimeout(() => {
        questionToUpdate.visible = true;
      });
    }
  }

  /**
   * @description Updates the form control with error message
   * @param { string } questionId The Id of question to update
   * @param { string } errorMessage Validation error message
   * @returns { void }
   */
  updateControlWithValidationMessage(
    questionId: string,
    tableQuestionId?: string,
    errorMessage?: string,
    rowId?: string | null,
  ): void {
    if (rowId && !tableQuestionId) {
      tableQuestionId = this.getTableIdFromQuestionId(
        this.selectedStep!,
        questionId,
      );
    }
    if (rowId && tableQuestionId) {
      const cell = this.getCellFromTableInStep(
        this.selectedStep!,
        tableQuestionId,
        questionId,
        rowId,
      );
      if (cell) {
        questionId = cell.id;
      } else {
        return;
      }
    }
    const formControlToPatch = this.form.get(questionId);
    if (formControlToPatch && errorMessage) {
      formControlToPatch.addValidators(this.customErrorValidator(errorMessage));
      formControlToPatch.markAsDirty();
      formControlToPatch.updateValueAndValidity();
    }
  }

  private getTableIdFromQuestionId(
    step: IStep,
    questionId: string,
  ): string | undefined {
    const tableQuestions = this.questionsFromStep(step).filter(
      (question) => question.questionType === QuestionType.Table,
    ) as TableQuestion[];
    const tableQuestion = tableQuestions.find(
      (question) =>
        question.tableColumns?.some(
          (column) => column.headerQuestion.id === questionId,
        ) ||
        question.tableColumns?.some((column) =>
          column.footerQuestions.some((fq) => fq.id === questionId),
        ),
    );
    return tableQuestion?.id;
  }

  private getCellFromTableInStep(
    step: IStep,
    tableQuestionId: string,
    questionId: string,
    rowId: string,
  ): TableAnswer | undefined {
    const tableQuestion = this.tableFromStep(step, tableQuestionId);
    const column = tableQuestion?.tableColumns?.find(
      (column) => column.headerQuestion.id === questionId,
    );
    return column?.cells.find((cell) => cell.rowId === rowId);
  }

  /**
   * @description Validator for custom error message
   * @param { string } errorMessage Validation error message
   * @returns { ValidatorFn } Validator
   */
  customErrorValidator(errorMessage?: string): ValidatorFn {
    return (_: AbstractControl): ValidationErrors | null => {
      if (errorMessage) {
        return { customError: errorMessage };
      }
      return null;
    };
  }

  /**
   * @description Updates the form value for a question
   * @param { string } questionId The Id of question to update
   * @param { string } value The value that the question is to be updated with
   * @returns { void }
   */
  private updateFormValue(questionId: string, value: string): void {
    const formToPatch = this.form.get(questionId);
    formToPatch?.patchValue(value);
    formToPatch?.updateValueAndValidity();
  }

  removeQuestionFormControl(question: BaseQuestion): void {
    this.form.removeControl(question.id);
  }

  /**
   * @description Adds the question to the group
   * @param { any } group Group the question is to be added to
   * @param { BaseQuestion } question Question to be added to group
   * @returns { void }
   */
  addQuestionFormControl<T>(
    group: CustomFormControl,
    question: Question<T>,
  ): void {
    group[question.id] = new FormControl(
      { value: question.value ?? '', disabled: !question.enabled },
      question.required ? Validators.required : null,
    );
    group[question.id].title = question.title;
    group[question.id].id = question.id;
  }

  /**
   * @description Execution of action from step submission component. View/Proceed/Exit
   * @param { StepSubmittedAction } action Action to perform from step submission component
   * @returns { void }
   */
  stepSubmissionAction(action: StepSubmittedAction): void {
    switch (action) {
      case StepSubmittedAction.Proceed:
        this.updateFlow(this.flow!);
        this.currentShowComponentEnum = AvailableComponent.StepDetail;
        break;
      case StepSubmittedAction.View:
        this.currentShowComponentEnum = AvailableComponent.StepDetail;
        break;
      case StepSubmittedAction.Exit:
        this.saveAndExit();
        break;
    }
  }

  /**
   * @description Updates the flow for the stepper.
   * @param { IFlow } flow Flow of the form
   * @param { boolean } changeStep Whether to change the step or stay on same one to view it
   * @param { boolean } loadFormGroup Whether we want to reload the form controls on the page
   * @returns { void }
   */
  public updateFlow(
    flow: IFlow,
    changeStep: boolean = true,
    loadFormGroup: boolean = true,
    stepToUpdateId: string | undefined = undefined,
  ): void {
    this.flow$ = of(flow);
    this.flow = flow;
    this.formGuid = flow.id;

    if (changeStep) {
      const displayStepId = stepToUpdateId ?? flow.currentStepId;
      this.stepIndex = flow.steps.findIndex(
        (step) => step.id === displayStepId,
      );
      if (this.stepIndex > -1) {
        this.onSelectedStepChange(flow.steps[this.stepIndex], loadFormGroup);
      }
    } else {
      let step = flow.steps.find((s) => s.id === this.selectedStep?.id);
      step && this.updateCurrentStepProperties(step);
    }
  }

  /**
   * @description Updates current step properties
   * @param { IStep } step The step with new properties
   * @returns { void }
   */
  public updateCurrentStepProperties(step: IStep): void {
    if (this.selectedStep === undefined) {
      return;
    }

    this.selectedStep.hasPermission = step.hasPermission;
    this.selectedStep.assignedTo = step.assignedTo;
    this.selectedStep.isReadOnly = step.isReadOnly;
  }

  /**
   * @description Updates the selected step and optionally the form controls
   * @param { IStep } step The step to updated the selected step to
   * @param { boolean } loadFormGroup Whether we want to reload the form controls on the page
   * @returns { void }
   */
  onSelectedStepChange(step?: IStep, loadFormGroup: boolean = true): void {
    this.cancelReopenMode();

    this.selectedStep = step;
    if (this.selectedStep) {
      this.resetStepDetailComponentProperties();

      this.currentShowComponentEnum = AvailableComponent.StepDetail;
      this.lastStep =
        this.selectedStep.id ===
        this.flow!.steps[this.flow!.steps?.length - 1].id;
      this.updateCurrentTime(this.selectedStep);
    }
    if (loadFormGroup) {
      if (this.form) {
        this.clearFormValidatorsOfErrors(this.form);
      }
      this.loadFormGroup();
      if (this.stepDetailComponent) {
        this.stepDetailComponent.updateFormGroupForValidationSummary(this.form);
      }
    }
  }

  onStepEvent(stepEvent: StepEvent) {
    const stepCompleted = this.selectedStep?.completedBy;
    this._flowService
      .submitStep(
        new SubmitStepRequest(
          this.formGuid!,
          stepEvent.stepId,
          stepEvent.ruleTriggerEvent,
          stepEvent.files,
        ),
      )
      .subscribe((response: PatchFlowEventResponse) => {
        this.isAffectedQuestionsWithErrors = false;
        // Data validation fires on step submitted, this is to check the result.
        // If any affected question, step is not submitted and the questions are updated with error.
        if (
          response.affectedQuestions &&
          response.affectedQuestions.length > 0
        ) {
          this.updateFormGroup(response.affectedQuestions, response.flow);
        }
        if (!this.isAffectedQuestionsWithErrors) {
          // Step was completed without any validation error clear form of errors
          this.clearFormValidatorsOfErrors(this.form);
          this.updateFlow(response.flow, true, false, stepEvent.stepId);

          this.currentShowComponentEnum =
            stepEvent.ruleTriggerEvent === RuleTriggerEvent.StepCompleting
              ? AvailableComponent.StepSubmitted
              : AvailableComponent.StepDetail;
        }
        this.checkFormActions(response, stepCompleted);
      });
  }

  /**
   * @description Go through the form actions to be executed
   * @param { PatchFlowEventResponse } response Response after step was submitted
   * @param { string | undefined } stepCompleted Undefined if step was not completed
   * @returns { void }
   */
  private checkFormActions(
    response: PatchFlowEventResponse,
    stepCompleted: string | undefined,
  ): void {
    response.formActions?.forEach((formAction) => {
      if (formAction.formActionType === FormActionType.OpenFileInNewTab) {
        this.openFileInNewTab(
          formAction as OpenFileInNewTab,
          response.flow,
          stepCompleted,
        );
      }
    });
  }

  /**
   * @description If step was completed and openInNewTab is true,
   * then open the generated document in new tab if pdf or download if docx
   * @param { OpenFileInNewTab } formAction Form action to be executed
   * @param { IFlow } responseFlow Response flow after step was submitted
   * @param { string | undefined } stepCompleted Undefined if step was not completed
   * @returns { void }
   */
  private openFileInNewTab(
    formAction: OpenFileInNewTab,
    responseFlow: IFlow,
    stepCompleted: string | undefined,
  ): void {
    const responseStep = responseFlow.steps?.find(
      (s) => s.id === this.selectedStep?.id,
    );
    // Check if completed by was updated then step was completed
    if (!stepCompleted && responseStep?.completedBy) {
      this._fileService.downloadFileData(
        formAction.displayName,
        formAction.fileUri,
        FileUploadStorageType.Azure,
        true,
      );
    }
  }

  /**
   * @description Remove validators from form
   * @param { FormGroup } formGroup FormGroup to be clearer of errors
   * @returns { void }
   */
  clearFormValidatorsOfErrors(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((formControlName) => {
      const formControl = this.form.get(formControlName);
      formControl?.clearValidators();
      formControl?.updateValueAndValidity();
    });
  }

  onFormRuleEvent(ruleTriggerEvent: RuleTriggerEvent) {
    this._flowService
      .patchFlow(new PatchFlowRequest(this.formGuid!, ruleTriggerEvent))
      .subscribe((response: PatchFlowEventResponse) => {
        this.updateFlow(response.flow);
      });
  }

  onQuestionEvent(questionEvent: QuestionEvent) {
    const formControlToPatch = this.form.get(questionEvent.questionId);
    this._flowService
      .submitQuestion(
        new SubmitQuestionRequest(
          this.formGuid!,
          questionEvent.questionId,
          questionEvent.ruleTriggerEvent,
          questionEvent.newValue,
          questionEvent.files,
        ),
      )
      .subscribe((response: PatchFlowEventResponse) => {
        FormComponent.handleCustomValidationOnResponse(
          response,
          questionEvent,
          formControlToPatch,
          this.form,
        );
        if (questionEvent.files) {
          this.updateUploadedFileId(
            response.flow,
            response.affectedQuestions!,
            questionEvent.questionId,
            questionEvent.invalidFileUploadDetails,
          );
        }
        this.updateFormGroup(response.affectedQuestions, response.flow);
        this.updateFlow(response.flow, false);
      });
  }

  static handleCustomValidationOnResponse(
    response: PatchFlowEventResponse,
    questionEvent: QuestionEvent,
    formControlToPatch: AbstractControl<any> | null,
    form: FormGroup,
  ): void {
    if (
      !response.affectedQuestions?.find(
        (af) => af.questionId == questionEvent.questionId,
      ) &&
      !this.isQuestionFileUpload(questionEvent)
    ) {
      // Since custom error is only validation allowed, its only one that needs to be removed on return
      // This should skip file upload questions since they have custom validators that are used.
      this.removeOnlyCustomError(formControlToPatch);
    }
    // Affected questions that don't have error messages can have validators removed if exist
    response.affectedQuestions?.forEach((affectedQuestion) => {
      if (!affectedQuestion.errorMessage) {
        const affectedControlToPatch = form.get(affectedQuestion.questionId);
        this.removeOnlyCustomError(affectedControlToPatch);
      }
    });
  }

  /**
   * @description Add new uploaded file Id to affected question to value is patched in the form.
   * @param { IFlow } flow The update flow after question has changed
   * @param { AffectedQuestion[] } affectedQuestions Current list of affected questions
   * @param { string } questionId The id of question changed
   * @returns { void }
   */
  updateUploadedFileId(
    flow: IFlow,
    affectedQuestions: AffectedQuestion[],
    questionId: string,
    invalidFileUploadDetails?: FileUploadDetails[],
  ): void {
    const updatedQuestion = flow.steps
      ?.find((step) => step.id == this.selectedStep?.id)
      ?.questions?.find((question) => question.id == questionId) as
      | FileUpload
      | undefined;

    // This is to keep invalid form control with the files that were invalidated on frontend validators
    for (let fileDetails of invalidFileUploadDetails ?? []) {
      updatedQuestion?.value?.push(fileDetails);
    }

    if (updatedQuestion) {
      const uploadAffectedQuestion = new AffectedQuestion();
      uploadAffectedQuestion.questionId = questionId;
      uploadAffectedQuestion.newValue = updatedQuestion.value;
      uploadAffectedQuestion.actions = [AffectedQuestionAction.QuestionUpdate];
      affectedQuestions.push(uploadAffectedQuestion);
    }
  }

  /**
   * @description Add new uploaded file cell to affected question to patch value in the form
   * @param { IFlow } flow The update flow after question has changed
   * @param { AffectedQuestion[] } affectedQuestions Current list of affected questions
   * @param { string } questionId The id of Table question
   * @param { string } cellId The id of cell changed
   * @param { FileUploadDetails[] } invalidFileUploadDetails List of invalid file upload details from frontend validation
   * @returns { void }
   */
  updateTableCellUploadedFileId(
    flow: IFlow,
    affectedQuestions: AffectedQuestion[],
    questionId: string,
    cellId: string,
    invalidFileUploadDetails?: FileUploadDetails[],
  ): void {
    const updatedTable = flow.steps
      ?.find((step) => step.id == this.selectedStep?.id)
      ?.questions?.find((question) => question.id == questionId) as
      | TableQuestion
      | undefined;

    let responseCell: TableAnswer | undefined;

    // Header question id
    const tableColumn = updatedTable?.tableColumns?.find((column) => {
      const foundCell = column.cells.find((cell) => cell.id == cellId);
      responseCell = foundCell;
      return foundCell ? { column, cell: foundCell } : undefined;
    });

    if (!tableColumn || !responseCell) {
      return;
    }

    const uploadAffectedQuestion = new AffectedQuestion();
    uploadAffectedQuestion.questionId = tableColumn.headerQuestion.id;
    uploadAffectedQuestion.newValue = responseCell.value;
    uploadAffectedQuestion.actions = [
      AffectedQuestionAction.TableQuestionUpdate,
    ];
    uploadAffectedQuestion.rowId = responseCell.rowId;

    for (let fileDetails of invalidFileUploadDetails ?? []) {
      uploadAffectedQuestion.newValue?.push(fileDetails);
    }

    affectedQuestions.push(uploadAffectedQuestion);
  }

  /**
   * @description Checks if the question event is from file upload question
   * @param { QuestionEvent } questionEvent Question event
   * @returns { boolean } returns true if question event is from file upload question
   */
  private static isQuestionFileUpload(questionEvent: QuestionEvent): boolean {
    return !!questionEvent.files && !!questionEvent.invalidFileUploadDetails;
  }

  /**
   * @description Remove all validators except required.
   * @param { AbstractControl<any> } formControlToPatch The control to remove validators on
   * @returns { void }
   */
  static removeOnlyCustomError(
    formControlToPatch: AbstractControl<any> | null,
  ): void {
    const formValidators = (formControlToPatch as ControlWithValidators)
      ?._rawValidators;
    // If no validators exist return
    if (!formValidators) {
      return;
    }
    const filteredFormValidators = formValidators.filter(
      (x) => x.name == 'required',
    );
    this.clearValidationMessage(formControlToPatch);
    formControlToPatch?.setValidators(filteredFormValidators);
    formControlToPatch?.updateValueAndValidity();
  }

  /**
   * @description Clears error from control, and remove validators
   * @param { AbstractControl<any> } formControlToPatch The control to remove validators on
   * @returns { void }
   */
  static clearValidationMessage(
    formControlToPatch: AbstractControl<any> | null,
  ): void {
    formControlToPatch?.setErrors(null);
    formControlToPatch?.clearValidators();
    formControlToPatch?.updateValueAndValidity();
  }

  /**
   * @description Execution of action table event
   * @param { PatchFlowTableEventResponse } tableEvent Table event response
   * @returns { void }
   */
  onTableEvent(tableEvent: PatchFlowTableEventResponse): void {
    if (tableEvent.files && tableEvent.affectedQuestions && tableEvent.cellId) {
      this.updateTableCellUploadedFileId(
        tableEvent.flow,
        tableEvent.affectedQuestions,
        tableEvent.tableQuestion.id,
        tableEvent.cellId,
        tableEvent.invalidFileUploadDetails,
      );
    }

    this.updateFormGroup(
      tableEvent.affectedQuestions,
      tableEvent.flow,
      tableEvent.tableQuestion.id,
    );

    // Get the table from the response
    const responseSelectedStep = tableEvent.flow.steps?.find(
      (response) => response.id == this.selectedStep?.id,
    );
    let responseTable = this.tableFromStep(
      responseSelectedStep,
      tableEvent.tableQuestion.id,
    );

    if (!responseTable) return;

    switch (tableEvent.operationType) {
      case TableOperationType.DuplicateRow:
        if (tableEvent.rowId == undefined) {
          break;
        }
        this.handleTableOperationDuplicateRow(responseTable, tableEvent.rowId);
        break;
      case TableOperationType.AddRow:
        this.handleTableOperationAddRow(responseTable);
        break;
      case TableOperationType.RemoveRow:
        this.handleTableOperationRemoveRow(
          responseTable,
          tableEvent.tableQuestion,
        );
        break;
      default:
        break;
    }

    this.updateFlow(tableEvent.flow, false);
  }

  /**
   * @description Flattens the questions from a step including inside section questions
   * @param { IStep | undefined } step Step which to get questions from
   * @returns { BaseQuestion[] } All questions from the step including inside section questions
   */
  questionsFromStep(step: IStep | undefined): BaseQuestion[] {
    return (
      step?.questions?.flatMap((question) => {
        if (question instanceof Section) {
          return this.flattenQuestions(question?.questions ?? []);
        } else {
          return [question];
        }
      }) ?? []
    );
  }

  /**
   * @description Gets the table from a step
   * @param { IStep | undefined } step Step which to get table from
   * @param { string } tableQuestionId The id of the table question
   * @returns { TableQuestion } The table from the step
   */
  tableFromStep(
    step: IStep | undefined,
    tableQuestionId: string,
  ): TableQuestion | undefined {
    const tableQuestion = this.questionsFromStep(step).find(
      (question) => question.id == tableQuestionId,
    );

    return tableQuestion ? (tableQuestion as TableQuestion) : undefined;
  }

  /**
   * @description Updates selected step table question with new question table columns,
   * and updates the table itself
   * @param { TableQuestion } responseTable The table to update
   * @returns { void }
   */
  updateTable(responseTable: TableQuestion): void {
    if (!this.selectedStep?.questions) {
      return;
    }
    // Update just table columns in selected step of specific question
    for (let question of this.selectedStep.questions) {
      if (question.questionType === QuestionType.Section) {
        const flattenedQuestions = this.flattenQuestions(
          (question as Section).questions!,
        );
        const foundQuestionIndex = flattenedQuestions.findIndex(
          (q) => q.id === responseTable.id,
        );
        if (foundQuestionIndex !== -1) {
          (
            (question as Section).questions![
              foundQuestionIndex
            ] as TableQuestion
          ).tableColumns = responseTable.tableColumns;
        }
      } else if (question.id === responseTable.id) {
        const index = this.selectedStep.questions.indexOf(question);
        if (index !== -1) {
          (this.selectedStep.questions[index] as TableQuestion).tableColumns =
            responseTable.tableColumns;
        }
      }
    }

    this._tableFeaturesService.updateTableData(responseTable);
  }

  /**
   * @description Flattens the questions into a single array
   * @param { BaseQuestion[] } questions Questions to be flattened
   * @returns { BaseQuestion[] } Flattened questions
   */
  flattenQuestions(questions: BaseQuestion[]): BaseQuestion[] {
    return questions.reduce<BaseQuestion[]>((flattened, question) => {
      flattened.push(question);
      if (question instanceof Section) {
        flattened.push(...this.flattenQuestions(question.questions!));
      }
      return flattened;
    }, []);
  }

  handleTableOperationDuplicateRow(
    responseTable: TableQuestion,
    rowId: string,
  ) {
    // Add controls for cells
    for (const tableColumn of responseTable.tableColumns ?? []) {
      let originalCellIndex = tableColumn.cells.findIndex(
        (c) => c.rowId == rowId,
      );
      let tableCell = tableColumn.cells[originalCellIndex + 1];
      this.addTableCellFormControls(
        tableColumn.headerQuestion,
        tableCell,
        this.form.controls as CustomFormControl,
      );
    }

    // Update table question in selected step
    this.updateTable(responseTable);
  }

  handleTableOperationAddRow(responseTable: TableQuestion) {
    // Add controls for cells
    for (const tableColumn of responseTable.tableColumns ?? []) {
      let tableCell = tableColumn.cells[tableColumn.cells.length - 1];
      this.addTableCellFormControls(
        tableColumn.headerQuestion,
        tableCell,
        this.form.controls as CustomFormControl,
      );
    }

    // Update table question in selected step
    this.updateTable(responseTable);
  }

  handleTableOperationRemoveRow(
    responseTable: TableQuestion,
    currentTable: TableQuestion,
  ) {
    // Remove controls for cells
    for (const tableColumn of currentTable.tableColumns ?? []) {
      for (const tableCell of tableColumn.cells ?? []) {
        const cellExistsInNewTable = responseTable.tableColumns!.some(
          (newTableColumn) =>
            newTableColumn.cells.some(
              (newTableCell) => newTableCell.id === tableCell.id,
            ),
        );

        if (!cellExistsInNewTable) {
          this.removeTableCellFormControls(tableCell);
        }
      }
    }

    // Update table question in selected step
    this.updateTable(responseTable);
  }

  /**
   * @description Gets the current selected step and goes to the step after that
   * @returns { void }
   */
  goNextStep(): void {
    this.stepIndex = this.flow!.steps.findIndex(
      (step) => step.id === this.selectedStep?.id,
    );
    let currentStepIndex = this.stepIndex;
    this.onSelectedStepChange(this.flow!.steps[++currentStepIndex]);
  }

  /**
   * @description Checks if the form has been submitted and gets correct text for button
   * @returns { string } The text to display on the exit button
   */
  get exitBtnText(): string {
    return !this.flow?.isFlowCompleted &&
      this.selectedStep?.isActive &&
      !this.selectedStep?.isReadOnly
      ? 'Save & Exit'
      : 'Exit';
  }

  /**
   * @description Opens form summary view
   * @returns { void }
   */
  onSummaryClick(): void {
    this.currentShowComponentEnum = AvailableComponent.FormSummary;
    this.collapseStepper();
    this.onSelectedStepChange();
  }

  stepFeedbackAction(stepFeedback: Feedback) {
    this._flowService
      .submitStepFeedback(
        this.formGuid!,
        this.selectedStep!.id!,
        new StepFeedbackRequest(stepFeedback),
      )
      .subscribe(() => {});
  }

  onStepNoteEvent(stepNote: Note) {
    this._flowService
      .submitStepNote(
        this.formGuid!,
        this.selectedStep!.id!,
        new StepNoteRequest(stepNote.text, stepNote.createdDate, stepNote.id),
      )
      .subscribe(() => {});
  }

  addFeedback(feedbackEvent: AddFeedbackEvent): void {
    this.feedbackPanelData = { ...feedbackEvent };
    this.reopenMode = true;
    this.archiveQuestionComments();
  }

  changeComponentView(componentToShow: AvailableComponent): void {
    this.currentShowComponentEnum = componentToShow;
  }

  getWorkflowHistoryEventList(): void {
    this._flowService
      .getFormHistory(this.formGuid!)
      .pipe(take(1))
      .subscribe((response: FormHistory) => {
        this.allStepsTitles = new Map(Object.entries(response.allStepsTitles));
        this.flowEvents = response.events;
      });
  }

  changeDueDate(date: Date): void {
    this.feedbackPanelData!.dueDate = date;
  }

  confirmReopen(reopen: boolean): void {
    if (reopen) {
      // Set dueDate to end of day
      this.feedbackPanelData?.dueDate.setHours(23, 59, 59, 999);

      this._flowService
        .reopenStep(
          this.formGuid!,
          this.selectedStep!.id!,
          new StepReopenRequest(
            this.feedbackPanelData!.dueDate,
            this.extractUpdatedQuestionComments(),
          ),
        )
        .subscribe((response: IFlow) => {
          this.updateFlow(response, true, true, response.currentStepId);
          this.cancelReopenMode();
        });
    } else {
      this.cancelReopenMode();
      this.restoreArchivedQuestionComments();
    }
  }

  confirmFormReopen(formReopenRequest: FormReopeningData): void {
    this._flowService
      .reopenForm(
        this.formGuid!,
        new FormReopenRequest(
          formReopenRequest.stepToReopen,
          formReopenRequest.reasonForReopening,
        ),
      )
      .subscribe((response: IFlow) => {
        this.updateFlow(response, true, true, response.currentStepId);
      });
  }

  cancelReopenMode(): void {
    this.feedbackPanelData = undefined;
    this.reopenMode = false;
  }

  private restoreCommentToQuestion(question: BaseQuestion) {
    question.commentThread = this.archivedQuestionComments.find(
      (archivedQuestion) => archivedQuestion?.quetionId === question.id,
    );

    // Recursively handle Section type questions
    if (question.questionType === QuestionType.Section) {
      (question as Section).questions?.forEach((childQuestion) =>
        this.restoreCommentToQuestion(childQuestion),
      );
    }
  }

  restoreArchivedQuestionComments() {
    this.selectedStep?.questions.forEach((question) =>
      this.restoreCommentToQuestion(question),
    );
    this.archivedQuestionComments = []; // Clear the archived comments after restoring
  }

  private archiveCommentFromQuestion(question: BaseQuestion) {
    if (question.commentThread) {
      this.archivedQuestionComments.push(
        new ArchivedComments(
          question.id,
          question.commentThread.reason,
          question.commentThread.comments,
          question.commentThread.isAddressed,
          question.commentThread.otherReason,
        ),
      );
      question.commentThread = undefined;
    }

    // Recursively handle Section type questions
    if (question.questionType === QuestionType.Section) {
      (question as Section).questions?.forEach((childQuestion) =>
        this.archiveCommentFromQuestion(childQuestion),
      );
    }
  }

  archiveQuestionComments() {
    this.archivedQuestionComments = [];

    this.selectedStep?.questions.forEach((question) => {
      this.archiveCommentFromQuestion(question);
    });
  }

  private extractCommentsFromQuestion(
    question: BaseQuestion,
    threadCreationDate: Date,
  ): CreateCommentThreadDto[] {
    let comments: CreateCommentThreadDto[] = [];

    if (question.commentThread) {
      const updatedComments = question.commentThread.comments.map(
        (comment) => ({
          ...comment,
          timeStamp: threadCreationDate,
        }),
      );

      comments.push({
        ...question.commentThread,
        questionId: question.id,
        comments: updatedComments,
      } as CreateCommentThreadDto);
    }

    if (
      question.questionType === QuestionType.Section &&
      (question as Section).questions
    ) {
      comments = comments.concat(
        (question as Section).questions!.flatMap((subQuestion) =>
          this.extractCommentsFromQuestion(subQuestion, threadCreationDate),
        ),
      );
    }

    return comments;
  }

  extractUpdatedQuestionComments(): CreateCommentThreadDto[] {
    if (!this.selectedStep?.questions) {
      return [];
    }
    const threadCreationDate = new Date();
    return this.selectedStep.questions.flatMap((question) =>
      this.extractCommentsFromQuestion(question, threadCreationDate),
    );
  }

  onStepAttachmentEvent(stepAttachmentEvent: StepAttachmentEvent): void {
    switch (stepAttachmentEvent.eventType) {
      case StepAttachmentAction.Upload:
        this._flowService
          .stepAttachmentUpload(
            this.formGuid!,
            stepAttachmentEvent.stepId,
            new StepAttachmentUploadRequest(stepAttachmentEvent.files!),
          )
          .subscribe((response: IFlow) => {
            this.updateFlow(response, true, false, this.selectedStep!.id);
          });
        break;
      case StepAttachmentAction.Delete:
        this._flowService
          .stepAttachmentDelete(
            this.formGuid!,
            stepAttachmentEvent.stepId,
            stepAttachmentEvent.attachmentId!,
          )
          .subscribe((response: IFlow) => {
            this.updateFlow(response, true, false, this.selectedStep!.id);
          });
        break;
    }
  }

  toggleStepper(): void {
    this.isSideStepperExpanded = !this.isSideStepperExpanded;
    if (this.isSideStepperExpanded) this._scrollLockService.lockScroll();
    else this._scrollLockService.unlockScroll();
  }

  collapseStepper(): void {
    this.isSideStepperExpanded = false;
    this._scrollLockService.unlockScroll();
  }

  onSideStepperEvent(event: boolean): void {
    this.isSideStepperExpanded = event;
    this._scrollLockService.unlockScroll();
  }

  get canShowStepper(): boolean {
    return (
      !!this.selectedStep ||
      this.currentShowComponentEnum === AvailableComponent.FormSummary ||
      this.currentShowComponentEnum === AvailableComponent.WorkflowHistory
    );
  }

  get canShowSummaryActive(): boolean {
    return [
      AvailableComponent.FormSummary,
      AvailableComponent.WorkflowHistory,
    ].includes(this.currentShowComponentEnum);
  }

  onFormCancelEvent(formCancelEvent: FormCancelEvent): void {
    this._flowService.flowDelete(formCancelEvent.formId).subscribe(() => {});

    this._uriService.redirectToFlowFormaFormsList();
  }

  updateCurrentTime(selectedStep: IStep): void {
    if (selectedStep.dueDate) {
      this.currentTime = new Date();
      this.currentTimeUpdateInterval = setInterval(() => {
        this.currentTime = new Date();
      }, 60000);
    } else {
      clearInterval(this.currentTimeUpdateInterval);
    }
  }

  get getLogo() {
    return this.flow?.logo ?? this.defaultLogoImage;
  }

  /**
   * @description Resets the step detail component properties.
   * Used when we need to reset properties of the step detail component when changing steps.
   */
  resetStepDetailComponentProperties(): void {
    if (this.stepDetailComponent) {
      this.stepDetailComponent.isAddNoteButtonActive = true;
      this.stepDetailComponent.isAddNoteCommentActive = false;
    }
  }
}

export interface ControlWithValidators extends FormControl {
  _rawValidators?: any[];
}

export enum AvailableComponent {
  FormSummary,
  WorkflowHistory,
  StepDetail,
  StepSubmitted,
  None,
}

export interface CustomFormControl {
  [key: string]: {
    title?: string;
    id?: string;
  } & FormControl;
}
