<div [formGroup]="form" class="mb-3">
  <div [ngSwitch]="question.questionType" [id]="'ScrollTo' + question.id">
    <section-question
      *ngSwitchCase="questionType.Section"
      [question]="question"
      [form]="form"
      (questionEvent)="onValueChanged($event)"
      (tableEvent)="onTableEvent($event)"
      [readOnly]="readOnly"
      [reopenMode]="reopenMode"
      (questionComment)="onQuestionComment($event)"
      [flowId]="flowId"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </section-question>

    <single-line-of-text
      *ngSwitchCase="questionType.SingleLineOfText"
      [question]="question"
      [form]="form"
      (valueChanged)="onValueChanged($event)"
      [readOnly]="readOnly"
      [showErrorText]="showErrorText"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </single-line-of-text>

    <choice
      *ngSwitchCase="questionType.Choice"
      [question]="question"
      [form]="form"
      (valueChanged)="onValueChanged($event)"
      [readOnly]="readOnly"
      [showErrorText]="showErrorText"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </choice>

    <yes-no
      *ngSwitchCase="questionType.YesNo"
      [question]="question"
      [form]="form"
      (valueChanged)="onValueChanged($event)"
      [readOnly]="readOnly"
      [showErrorText]="showErrorText"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </yes-no>

    <multiple-lines-of-text
      *ngSwitchCase="questionType.MultipleLinesOfText"
      [question]="question"
      [form]="form"
      (valueChanged)="onValueChanged($event)"
      [readOnly]="readOnly"
      [showErrorText]="showErrorText"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </multiple-lines-of-text>

    <separator
      *ngSwitchCase="questionType.Separator"
      [question]="question"
      [form]="form"
    >
    </separator>

    <button-question
      *ngSwitchCase="questionType.Button"
      [question]="question"
      [form]="form"
      (valueChanged)="onValueChanged($event)"
      [readOnly]="readOnly"
      [showCommentIcon]="canShowCommentIcon()"
      [showErrorText]="showErrorText"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </button-question>

    <date-and-time
      *ngSwitchCase="questionType.DateAndTime"
      [question]="question"
      [form]="form"
      (valueChanged)="onValueChanged($event)"
      [readOnly]="readOnly"
      [showErrorText]="showErrorText"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </date-and-time>

    <number
      *ngSwitchCase="questionType.Number"
      [question]="question"
      [form]="form"
      (valueChanged)="onValueChanged($event)"
      [readOnly]="readOnly"
      [showErrorText]="showErrorText"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </number>

    <file-upload
      *ngSwitchCase="questionType.FileUpload"
      [question]="question"
      [form]="form"
      (valueChanged)="onValueChanged($event)"
      [readOnly]="readOnly"
      [showErrorText]="showErrorText"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </file-upload>

    <table-question
      *ngSwitchCase="questionType.Table"
      [question]="question"
      [form]="form"
      [flowId]="flowId"
      [readOnly]="readOnly"
      [showErrorText]="showErrorText"
      (tableEvent)="onTableEvent($event)"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </table-question>

    <person-or-group
      *ngSwitchCase="questionType.PersonOrGroup"
      [question]="question"
      [form]="form"
      [flowId]="flowId"
      [readOnly]="readOnly"
      [showErrorText]="showErrorText"
      (valueChanged)="onValueChanged($event)"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </person-or-group>

    <wet-signature
      *ngSwitchCase="questionType.WetSignature"
      [question]="question"
      [form]="form"
      [flowId]="flowId"
      [readOnly]="readOnly"
      [showErrorText]="showErrorText"
      (valueChanged)="onValueChanged($event)"
      [showSubtitle]="!tableId"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </wet-signature>

    <lookup
      *ngSwitchCase="questionType.Lookup"
      [question]="question"
      [form]="form"
      [flowId]="flowId"
      [readOnly]="readOnly"
      [showErrorText]="showErrorText"
      [tableId]="tableId"
      (valueChanged)="onValueChanged($event)"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </lookup>

    <div *ngSwitchCase="questionType.Calculated">
      <single-line-of-text
        *ngIf="$any(question).questionSubType === questionType.SingleLineOfText"
        [question]="question"
        [form]="form"
        (valueChanged)="onValueChanged($event)"
        [readOnly]="readOnly"
        [showErrorText]="showErrorText"
        [isCalculatedQuestion]="true"
      >
        <ng-container *ngTemplateOutlet="commentIcon" />
      </single-line-of-text>

      <number
        *ngIf="$any(question).questionSubType === questionType.Number"
        [question]="question"
        [form]="form"
        (valueChanged)="onValueChanged($event)"
        [readOnly]="readOnly"
        [showErrorText]="showErrorText"
        [isCalculatedQuestion]="true"
      >
        <ng-container *ngTemplateOutlet="commentIcon" />
      </number>

      <div *ngSwitchDefault></div>
    </div>

    <ng-template #commentIcon>
      <i
        *ngIf="canShowCommentIcon()"
        (click)="onQuestionCommentClick()"
        placement="bottom"
        [ngClass]="{
          'bi ms-1 cursor-pointer comment-icon': true,
          'bi-chat-dots text-primary': !isCommentIconActive(),
          'bi-chat-dots-fill text-primary': isCommentIconActive()
        }"
      ></i>
    </ng-template>
  </div>
  <question-comment-thread
    class="comment-thread"
    *ngIf="question.commentThread && showCommentThread"
    [commentThread]="question.commentThread"
    [question]="question"
    [isStepCompleted]="isStepCompleted"
    (questionComment)="onQuestionComment($event)"
  ></question-comment-thread>
  <div *ngIf="!question.commentThread">
    <b #target></b>
    <button
      type="button"
      class="d-none"
      #commentPopOver="ngbPopover"
      [ngbPopover]="popContent"
      (shown)="toggleCommentPopUp()"
      (hidden)="toggleCommentPopUp()"
      [autoClose]="'outside'"
      popoverClass="comment-popover ms-10"
      [positionTarget]="target"
      placement="bottom"
    ></button>
    <ng-template #popContent>
      <question-comment
        [question]="question"
        (saveComment)="onQuestionComment($event)"
        (closeComment)="onQuestionCommentClose()"
      ></question-comment>
    </ng-template>
  </div>
</div>
