import { Component, Input } from '@angular/core';
import { FFComponentBase } from '../ff-base/ff-component.base';

@Component({
  selector: 'ff-textbox',
  templateUrl: './ff-textbox.component.html',
})
export class FFTextBoxComponent extends FFComponentBase {
  @Input() value?: string;
  @Input() showComment!: boolean;
  @Input() isHyperlink: boolean = false;
  @Input() hyperlinkLabel?: string;
}
