<div class="mb-2">
  <ff-label
    [label]="label"
    [tooltip]="tooltip"
    [required]="required"
    [showTitle]="showTitle"
    [controlId]="id"
  />
  <ng-content />
</div>

<div
  *ngIf="value && value.length > 0"
  [ngClass]="{
    'd-flex flex-row': visualType === VisualType.Colors
  }"
>
  <div *ngFor="let option of value; let last = last" class="d-flex">
    <div
      class="d-flex gap-1"
      [ngClass]="{
        'flex-row-reverse justify-content-end':
          visualType === VisualType.Colors,
        'flex-column': visualType === VisualType.Images
      }"
    >
      <div
        *ngIf="
          visualMode === VisualMode.Text || visualMode === VisualMode.VisualText
        "
        [ngStyle]="{
          color:
            visualType === VisualType.Colors && visualMode === VisualMode.Text
              ? getMappingVisualValue(option)
              : ''
        }"
      >
        {{ option }}
      </div>
      <div
        *ngIf="
          visualType === VisualType.Images &&
          (visualMode === VisualMode.Visual ||
            visualMode === VisualMode.VisualText)
        "
      >
        <img
          [src]="getMappingVisualValue(option)"
          alt="{{ option }}"
          [ngStyle]="{
            'max-width.px': imageWidth,
            width: '100%',
            height: 'auto'
          }"
        />
      </div>
      <div
        *ngIf="
          visualType === VisualType.Colors &&
          (visualMode === VisualMode.Visual ||
            visualMode === VisualMode.VisualText)
        "
        class="shapeSize"
        [ngClass]="{
          square: shapeType === ShapeType.Square,
          circle: shapeType === ShapeType.Circle
        }"
        [class.rounded-circle]="shapeType === ShapeType.Circle"
        [style.backgroundColor]="getMappingVisualValue(option)"
      ></div>
    </div>
    <div *ngIf="!last && visualType === VisualType.Colors">,</div>
  </div>
</div>
