import { Component, OnInit } from '@angular/core';
import {
  FileUpload,
  FileAction,
  FileUploadDetails,
  FileValidationErrorType,
  FileError,
} from '../../../models/FileUpload';
import { QuestionBaseComponent } from '../question-base-component';
import { AbstractControl } from '@angular/forms';
import { FileService } from '../../../common/services/file.service';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
})
export class FileUploadComponent
  extends QuestionBaseComponent<FileUploadDetails[]>
  implements OnInit
{
  fileUnitMessage = 'MB: ';
  fileArray: File[] = [];
  fileListItemUploads: FileUploadDetails[] = [];
  fileUpload!: FileUpload;

  ngOnInit(): void {
    this.fileUpload = this.question as FileUpload;
    this.updateSavedFile(this.fileUpload.value);

    let control = this.form.get(this.fileUpload.id);
    control?.addValidators(this.fileExtensionValidator());
    control?.addValidators(this.fileSizeValidator());
    control?.addValidators(this.fileNameValidation());

    // We need the uploaded file Id for downloading the file
    this.form.get(this.fileUpload.id)?.valueChanges.subscribe((value) => {
      this.updateSavedFile(value);
    });
  }

  updateSavedFile(files: FileUploadDetails[] | undefined): void {
    // Check if the files contains FileUploadDetails or File objects
    if (files && files.length > 0 && files[0].displayName) {
      // Saved files counts only when it contains fileUrl
      this.fileListItemUploads = files.filter((file) => file.relativeFilePath);
    }
  }

  /**
   * @description Triggered after file was deleted, calls functions with deleted file details
   * @returns { void }
   */
  deleteFile(deletedFileDetails: FileUploadDetails[]): void {
    this.updateForFileChange(deletedFileDetails, [], FileAction.Delete);
  }

  /**
   * @description Triggered after file was added, gets file from event and called function with corrects params
   * @param { FileList | null } files The file being uploaded as a file list
   * @returns { void }
   */
  fileUploadChange(files: FileList | null): void {
    if (files?.length && files[0].name) {
      let control = this.form.get(this.fileUpload.id);
      control?.markAsDirty();

      const newValidFilesDetailsList: FileUploadDetails[] = [];
      const newInvalidFileDetailsList: FileUploadDetails[] = [];
      const validFiles: File[] = [];

      for (const uploadedFile of Array.from(files)) {
        let isFileValid = true;
        const fileUploadDetails = new FileUploadDetails(uploadedFile.name);
        fileUploadDetails.fileSize = uploadedFile.size;

        // Validation
        let isValidFileExtension = FileService.isValidFileExtension(
          uploadedFile,
          this.fileUpload.fileExtensionPermissionType,
          this.fileUpload.extensions,
        );
        let isValidFileSize = FileService.isValidFileSize(
          uploadedFile.size,
          this.fileUpload.fileSizeLimit,
        );
        let isFileNameUnique = FileService.isFileNameUnique(
          uploadedFile.name,
          this.fileListItemUploads,
        );
        if (!isValidFileExtension || !isValidFileSize || !isFileNameUnique) {
          isFileValid = false;
        }

        if (isFileValid) {
          validFiles.push(uploadedFile);
          newValidFilesDetailsList.push(fileUploadDetails);
        } else {
          newInvalidFileDetailsList.push(fileUploadDetails);
        }
      }
      this.updateForFileChange(
        newValidFilesDetailsList,
        validFiles,
        FileAction.Upload,
        newInvalidFileDetailsList,
      );
    }
  }

  /**
   * @description Makes changes to values after file change and emits value
   * @param { FileUploadDetails[] } fileUploadDetails Details of files
   * @param { File[] | undefined } uploadedFile Upload file object array
   * @returns { void }
   */
  updateForFileChange(
    fileUploadDetails: FileUploadDetails[],
    uploadedFile: File[],
    fileUploadAction: FileAction,
    invalidFileUploadDetails?: FileUploadDetails[],
  ): void {
    if (fileUploadAction === FileAction.Delete) {
      this.change(fileUploadDetails, [], []);
    } else if (fileUploadAction === FileAction.Upload) {
      if (!this.fileUpload.allowMultipleFiles) {
        this.fileArray = uploadedFile;
      }

      if (uploadedFile.length > 0) {
        this.change([], uploadedFile, invalidFileUploadDetails ?? []);
      } else if (
        invalidFileUploadDetails &&
        invalidFileUploadDetails.length > 0
      ) {
        const control = this.form.get(this.fileUpload.id);
        control?.patchValue([...control.value, ...invalidFileUploadDetails]);
      }
    }
  }

  fileExtensionValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let invalidFileNames: string[] = [];
      for (let uploadedFileDetails of control.value) {
        if (
          uploadedFileDetails.displayName &&
          this.fileUpload.extensions &&
          this.fileUpload.extensions.length > 0 &&
          !FileService.allowedOrDisallowedFileExt(
            uploadedFileDetails.displayName,
            this.fileUpload.fileExtensionPermissionType,
            this.fileUpload.extensions,
          )
        ) {
          invalidFileNames.push(uploadedFileDetails.displayName);
        } else if (
          uploadedFileDetails.errorMessages?.some((x: FileError) => {
            if (x.type === FileValidationErrorType.Extension) {
              invalidFileNames.push(x.message);
              return true;
            }
            return false;
          })
        ) {
          continue;
        }
      }
      if (invalidFileNames.length > 0) {
        return {
          invalidFileExtension: invalidFileNames,
        };
      }
      return null;
    };
  }

  fileSizeValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let invalidFileNames: string[] = [];
      for (let uploadedFileDetails of control.value) {
        if (
          uploadedFileDetails.fileSize &&
          this.fileUpload.fileSizeLimit &&
          !FileService.isValidFileSize(
            uploadedFileDetails.fileSize,
            this.fileUpload.fileSizeLimit,
          )
        ) {
          invalidFileNames.push(
            this.fileUpload.fileSizeLimit +
              this.fileUnitMessage +
              uploadedFileDetails.displayName,
          );
        } else if (
          uploadedFileDetails.errorMessages?.some((x: FileError) => {
            if (x.type === FileValidationErrorType.Size) {
              invalidFileNames.push(
                this.fileUpload.fileSizeLimit +
                  this.fileUnitMessage +
                  x.message,
              );
              return true;
            }
            return false;
          })
        ) {
          continue;
        }
      }
      if (invalidFileNames.length > 0) {
        return {
          invalidFileSize: invalidFileNames,
        };
      }
      return null;
    };
  }

  fileNameValidation() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let invalidFileNames: string[] = [];
      for (let uploadedFileDetails of control.value) {
        if (
          uploadedFileDetails.displayName &&
          uploadedFileDetails.fileSize != undefined &&
          !FileService.isFileNameUnique(
            uploadedFileDetails.displayName,
            this.fileListItemUploads,
          )
        ) {
          invalidFileNames.push(uploadedFileDetails.displayName);
        } else if (
          uploadedFileDetails.errorMessages?.some((x: FileError) => {
            if (x.type === FileValidationErrorType.Name) {
              invalidFileNames.push(x.message);
              return true;
            }
            return false;
          })
        ) {
          continue;
        }
      }
      if (invalidFileNames.length > 0) {
        return {
          invalidFileName: invalidFileNames,
        };
      }
      return null;
    };
  }
}
