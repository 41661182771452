<div>
  <div class="mb-2">
    <ff-label
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
      [showTitle]="showTitle"
      [controlId]="id"
    />
    <ng-content />
  </div>

  <ff-subtitle *ngIf="showSubtitle" [subtitle]="subtitle" />

  <ff-control-validation
    *ngIf="hasErrors && showErrorText"
    [errors]="control.errors"
    [label]="label"
  />

  <div
    class="d-flex align-items-center"
    [ngClass]="{
      'flex-column gap-2': !horizontalLayout,
      'gap-4': horizontalLayout
    }"
    [id]="id"
  >
    <div
      class="d-flex gap-2 align-self-stretch"
      [ngClass]="{ 'd-inline-flex': !horizontalLayout }"
      *ngFor="let option of options; let index = index"
    >
      <p-radioButton
        [value]="option.value || option"
        [formControl]="formControl"
        [inputId]="id! + index"
        [class.ng-invalid]="hasErrors"
        [class.ng-dirty]="hasErrors"
        (onClick)="onChangeEvent()"
        styleClass="ffx-radiobutton"
      ></p-radioButton>
      <label
        class="form-check-label d-flex align-items-start gap-2 overflow-hidden"
        [for]="id! + index"
        [class.text-danger]="hasErrors"
        [ngClass]="{
          'flex-row-reverse': visualType === VisualType.Colors,
          'flex-column': visualType === VisualType.Images
        }"
      >
        <div
          class="overflow-hidden text-overflow"
          *ngIf="visualMode !== VisualMode.Visual"
          [ngStyle]="{
            color:
              visualType === VisualType.Colors && visualMode === VisualMode.Text
                ? getMappingVisualValue(option)
                : ''
          }"
        >
          {{ option.displayName || option }}
        </div>
        <div
          *ngIf="
            visualType === VisualType.Images &&
            (visualMode === VisualMode.Visual ||
              visualMode === VisualMode.VisualText)
          "
        >
          <img
            [src]="getMappingVisualValue(option.value || option)"
            alt="{{ option.value || option }}"
            [ngStyle]="{
              'max-width.px': imageWidth,
              width: '100%',
              height: 'auto'
            }"
          />
        </div>
        <div
          *ngIf="
            visualType === VisualType.Colors &&
            (visualMode === VisualMode.Visual ||
              visualMode === VisualMode.VisualText)
          "
          class="shapeSize"
          [ngClass]="{
            square: shapeType === ShapeType.Square,
            circle: shapeType === ShapeType.Circle
          }"
          [class.rounded-circle]="shapeType === ShapeType.Circle"
          [style.backgroundColor]="
            getMappingVisualValue(option.value || option)
          "
        ></div>
      </label>
    </div>
  </div>
</div>
