import { Directive, EventEmitter, Input, Output, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { FFComponentBase } from './ff-component.base';
import { StepDetailComponent } from 'src/app/components/steps/step-detail/step-detail.component';

type OnChangeFn<T> = (value: T) => void;
type OnTouchFn = () => void;

@Directive()
export class FFInputComponentBase
  extends FFComponentBase
  implements ControlValueAccessor
{
  // Fields
  protected onChanged: OnChangeFn<any> = () => {};
  protected onTouched: OnTouchFn = () => {};

  // External
  @Input() showErrorText: boolean = true;

  @Output() OnChange = new EventEmitter<any>();
  @Output() OnKeyUp = new EventEmitter<any>();

  // Properties
  public formControl: FormControl = new FormControl();

  // Constructor
  constructor(@Self() public control: NgControl) {
    super();
    this.control && (this.control.valueAccessor = this);

    // option 2
    this.formControl.valueChanges.subscribe((value: any) => {
      this.OnKeyUp.emit(value);
    });

    this.formControl.statusChanges.subscribe((status: string) => {
      if (status === 'INVALID') {
        console.log('Invalid value:', this.control.value);
      }
    });
  }

  ngOnInit(): void {
    this.setDisabledState(!this.enabled);
    this.showErrorText = this.showErrorText ?? true;
  }

  // Control Value Accessor
  registerOnChange(fn: OnChangeFn<any>): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: OnTouchFn): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  }

  // write to the view/to the element
  writeValue<T>(obj: T): void {
    this.formControl.patchValue(obj);
    this.onTouched();
  }

  // Methods
  public get hasErrors(): boolean {
    if (!this.control) {
      return false;
    }

    const { dirty, touched } = this.control;

    return !!(this.invalid && (dirty || touched));
  }

  public get invalid(): boolean {
    return this.control.invalid!;
  }

  public enable() {
    this.control?.control?.enable();
  }

  public disable() {
    this.control?.control?.disable();
  }

  public toggleEnabled() {
    this.formControl.enabled ? this.disable() : this.enable();
  }

  // Actions for Event Handlers
  onChangeEvent<T>(value: T): void {
    this.onChanged(value);
    const errors = this.control.control?.errors;
    if (StepDetailComponent.onlyDataValidationErrors(errors)) {
      this.OnChange.emit(value);
    }
  }
}
