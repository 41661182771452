<div>
  <div class="mb-2">
    <ff-label
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
      [showTitle]="showTitle"
      [controlId]="id"
    />
    <ng-content />
  </div>
  <ff-control-validation
    *ngIf="hasErrors && showErrorText"
    [errors]="control.errors"
    [label]="label"
  />
  <ff-subtitle *ngIf="showSubtitle" [subtitle]="subtitle" />
  <div>
    <p-dropdown
      [placeholder]="placeHolder"
      [options]="displayOptions"
      [showClear]="showClear"
      [formControl]="formControl"
      [filter]="filter"
      [optionLabel]="'displayValue'"
      [class.ng-invalid]="hasErrors"
      [class.ng-dirty]="hasErrors"
      [overlayOptions]="getOverlayOptions()"
      (onClear)="onClear()"
      (onShow)="onShow()"
      styleClass="ffx-lookup ffx-select"
      appendTo="body"
    >
    </p-dropdown>
  </div>
</div>
