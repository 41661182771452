<div>
  <div class="mb-2">
    <ff-label
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
      [showTitle]="showTitle"
      [controlId]="id"
    />
    <ng-content />
  </div>

  <ff-subtitle *ngIf="showSubtitle" [subtitle]="subtitle" />

  <ff-control-validation
    *ngIf="hasErrors && showErrorText"
    [errors]="control.errors"
    [label]="label"
  />

  <input
    type="{{ this.formControl.value ? 'datetime-local' : 'text' }}"
    onfocus="(this.type='datetime-local')"
    (blur)="onBlur($event, 'datetime-local')"
    (touchstart)="onTouchStart($event)"
    (touchmove)="onTouchMove($event)"
    (touchend)="onTouchEnd($event, 'datetime-local')"
    class="form-control"
    max="9999-12-31T23:59"
    [placeholder]="placeHolder"
    [formControl]="formControl"
    [id]="id"
    [class.is-invalid]="hasErrors"
  />
</div>
