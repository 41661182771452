<div class="d-flex pb-2" *ngIf="invalidQuestionIds.length">
  <div
    class="error-count-circle bg-danger d-flex align-items-center justify-content-center rounded-circle"
  >
    <span class="text-white fw-bold">{{ invalidQuestionIds.length }}</span>
  </div>
  <p
    class="text-danger py-0 px-2 fw-bold d-lg-block"
    [ngClass]="{
      'd-none': !isSideStepperExpanded,
      'd-block': isSideStepperExpanded
    }"
  >
    Error<span *ngIf="invalidQuestionIds.length > 1">s</span> found
  </p>
</div>
<div
  class="d-flex pb-2"
  *ngIf="selectedStep?.isReOpened && countIssuesToReview() > 0"
>
  <div
    class="error-count-circle bg-warning d-flex align-items-center justify-content-center rounded-circle"
  >
    <span class="text-white fw-bold">{{ countIssuesToReview() }}</span>
  </div>
  <p
    class="text-warning py-0 px-2 fw-bold d-lg-block"
    [ngClass]="{
      'd-none': !isSideStepperExpanded,
      'd-block': isSideStepperExpanded
    }"
  >
    Issue<span *ngIf="countIssuesToReview() > 1">s</span> to review
  </p>
</div>
<div class="steps pt-1 ps-1">
  <div *ngFor="let step of steps" class="step position-relative">
    <div>
      <div
        class="step-circle step-circle-size position-absolute z-1 rounded-circle cursor-pointer"
        (click)="onSelectedStepChange(step)"
        [class.active]="showStepActive(step)"
        [class.submitted]="step?.completedBy"
        [class.selected]="step?.id === this.selectedStep?.id"
      ></div>
      <div
        class="line h-100 position-absolute"
        [class.active]="step?.submittedDate"
      ></div>
    </div>

    <ul
      class="list-group step-circle-size step-title list-unstyled fw-normal fs-6"
    >
      <li
        class="list-group-item pb-lg-0"
        [ngClass]="{
          'pb-5': !isSideStepperExpanded,
          'pb-2': isSideStepperExpanded
        }"
      >
        <div
          class="d-lg-block"
          [ngClass]="{
            'd-none': !isSideStepperExpanded,
            'd-block': isSideStepperExpanded
          }"
        >
          <span
            [class.active]="step?.isActive"
            [class.selected]="selectedStep?.id === step?.id"
            class="cursor-pointer"
            (click)="onSelectedStepChange(step)"
            >{{ step.title }}</span
          >
          <i
            *ngIf="step.completedBy && !step.isFutureStep"
            class="ms-1 bi bi-eye"
          ></i>
          <i
            *ngIf="!step.hasPermission && step.isFutureStep"
            class="ms-1 bi bi-lock"
          ></i>
          <i
            *ngIf="selectedStep?.id === step?.id && step.isActive"
            class="ms-1 bi bi-three-dots-vertical cursor-pointer"
            (click)="contextMenu.open()"
          >
            <ff-context-menu
              #contextMenu
              [itemsTemplate]="menuItems"
              [itemsTemplateContext]="{ step: step }"
            ></ff-context-menu>
          </i>
        </div>
        <div
          *ngIf="step?.submittedDate"
          class="fs-8 mb-3 d-lg-block"
          [ngClass]="{
            'd-none': !isSideStepperExpanded,
            'd-block': isSideStepperExpanded
          }"
        >
          Submitted
          {{ step?.submittedDate | mediumDatePipe }}
        </div>
        <div
          *ngIf="step?.description"
          class="fs-8 ms-2 d-lg-block"
          [ngClass]="{
            'd-none': !isSideStepperExpanded,
            'd-block': isSideStepperExpanded
          }"
        >
          {{ step?.description }}
        </div>
        <ul
          class="list-group step-section-title fs-7 list-unstyled fw-normal mt-2 d-lg-block"
          [ngClass]="{
            'd-block': isSideStepperExpanded,
            'd-none': !isSideStepperExpanded
          }"
          *ngIf="selectedStep?.id === step?.id"
        >
          <ng-container
            *ngFor="
              let question of selectedStep?.questions
                | filterStepper: invalidQuestionIds
            "
          >
            <li
              class="list-group-item cursor-pointer mb-2"
              [class]="getQuestionWithIssuesClass(question)"
              (click)="scrollToElementById(question.id)"
            >
              {{ question.title }}
              <ul
                class="list-group mt-2"
                *ngIf="question?.questionType === questionType.Section"
                style="list-style-type: circle"
              >
                <ng-container
                  *ngFor="
                    let sectionQuestion of getSectionQuestion(question.id)
                      | filterStepper: invalidQuestionIds
                  "
                >
                  <li
                    class="list-group-item cursor-pointer mb-2"
                    [class]="getQuestionWithIssuesClass(sectionQuestion)"
                    (click)="scrollToElementById(sectionQuestion.id)"
                  >
                    {{ sectionQuestion.title }}
                  </li>
                </ng-container>
              </ul>
            </li>
          </ng-container>
        </ul>
      </li>
    </ul>
  </div>
</div>
<ng-template #menuItems let-step="step">
  <div class="m-1">
    <button
      type="button"
      class="px-2"
      ngbDropdownItem
      *ngIf="selectedStep?.showDelegate"
      (click)="delegateStep(step)"
    >
      <i class="me-2 bi bi-box-arrow-in-down-right" title="Delegate"></i>
      <span>Delegate this step</span>
    </button>
    <button
      type="button"
      class="px-2"
      ngbDropdownItem
      *ngIf="selectedStep?.enableAttachments"
      (click)="addAttachments(step.id, step.unallowedAttachmentFileTypes)"
    >
      <i class="me-2 bi bi-paperclip" title="Attachments"></i>
      <span>Add attachments</span>
    </button>
    <button type="button" class="px-2" ngbDropdownItem>
      <i class="me-2 bi-envelope-plus" title="Recipient"></i>
      <span>Add recipient</span>
    </button>
  </div>
</ng-template>
