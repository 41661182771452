<div>
  <div class="mb-2">
    <ff-label
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
      [showTitle]="showTitle"
      [controlId]="id"
    />
    <ng-content />
  </div>

  <ff-subtitle *ngIf="showSubtitle" [subtitle]="subtitle" />

  <ff-control-validation
    *ngIf="hasErrors && showErrorText"
    [errors]="control.errors"
    [label]="label"
  />

  <div class="input-group mb-3">
    <div class="input-group-prepend" *ngIf="format === FormatType.Currency">
      <span class="input-group-text">{{ culture ?? "" | currencySymbol }}</span>
    </div>
    <p-inputNumber
      class="form-control d-flex rounded-1 p-0"
      [formControl]="formControl"
      [placeholder]="placeHolder ?? ''"
      [inputId]="id!"
      [title]="label ?? ''"
      [useGrouping]="disableThousandGrouping === false"
      [min]="minimum ?? MINIMUMPOSSIBLENUMBER"
      [max]="maximum ?? MAXIMUMPOSSIBLENUMBER"
      [minFractionDigits]="decimalPlaces ?? 0"
      [maxFractionDigits]="decimalPlaces ?? 0"
      [allowEmpty]="true"
      (onBlur)="updateValue()"
      [class.ng-invalid]="hasErrors"
      [class.ng-dirty]="hasErrors"
      [readonly]="isInputReadOnly!"
      [inputStyleClass]="isInputReadOnly ? 'isInputReadOnly' : ''"
      (onInput)="onInput($event)"
    ></p-inputNumber>

    <div class="input-group-append" *ngIf="format === FormatType.Percentage">
      <span class="input-group-text">%</span>
    </div>
  </div>
</div>
