import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { QuestionEvent } from 'src/app/components/steps/step-detail/step-detail.component';
import { BaseQuestion, Question } from 'src/app/models/Question';
import { TableCellValue } from 'src/app/models/TableQuestion';

@Component({
  selector: 'table-question-selector',
  templateUrl: './table-question-selector.component.html',
})
export class TableQuestionSelectorComponent implements OnInit {
  @Input() flowId!: string;
  @Input() question!: BaseQuestion;
  @Input() tableId?: string;
  @Input() form!: FormGroup;
  @Input() readOnly!: boolean;
  @Input() questionId!: string;
  @Input() value!: any;
  @Input() cardViewMode: boolean = false;
  @Input() showCommentIcon!: boolean;
  @Input() showErrorText!: boolean;
  @Input() colIndex?: number;
  @Input() visible = false;
  @Input() enabled = false;

  @Output()
  questionEvent = new EventEmitter<QuestionEvent>();

  tableCellQuestion?: Question<TableCellValue>;

  ngOnInit(): void {
    this.tableCellQuestion = { ...(this.question as Question<TableCellValue>) };
    this.tableCellQuestion.showTitle = this.cardViewMode && this.colIndex !== 0;
    this.tableCellQuestion.required =
      this.cardViewMode && this.tableCellQuestion.required;
    this.updateQuestionId(this.questionId);
    this.updateValue(this.value);

    this.tableCellQuestion.visible = this.visible;
    this.tableCellQuestion.enabled = this.enabled;
  }

  updateQuestionId(questionId: string): void {
    if (this.tableCellQuestion) {
      this.tableCellQuestion.id = questionId;
    }
  }

  updateValue(value: any): void {
    if (this.question) {
      (this.tableCellQuestion as Question<TableCellValue>).value = value;
      // Make sure the value is updated in the form
      const formControl = this.form.get(this.questionId);
      if (formControl && formControl.value !== value) {
        formControl.patchValue(value, { emitEvent: false });
      }
    }
  }

  onQuestionEvent(questionEvent: QuestionEvent): void {
    // update cell data for sorting
    this.questionEvent.emit(questionEvent);
  }
}
