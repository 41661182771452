import { Pipe } from '@angular/core';
import { BaseFFxDatePipe } from './base-ffx-date.pipe';

@Pipe({
  name: 'mediumDateTimePipe',
})
export class MediumDateTimePipe extends BaseFFxDatePipe {
  override get formatString(): string {
    return 'medium';
  }
}
