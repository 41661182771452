import { Directive, Input } from '@angular/core';

@Directive()
export class FFComponentBase {
  @Input() required?: boolean = false;
  @Input() label?: string;
  @Input() subtitle?: string;
  @Input() showSubtitle: boolean = false;
  @Input() tooltip?: string;
  @Input() placeHolder?: string;
  @Input() id?: string;
  @Input() showTitle?: boolean = true;
  @Input() enabled?: boolean = true;
  @Input() verticalLayout?: boolean = false;
  @Input() errorMessageSuffix?: string = undefined;
  @Input() showCommentIcon: boolean = false;
}
