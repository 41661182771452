import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import {
  CreateCommentThreadRequest,
  OnCommentRequest,
  Reason,
} from './question-comment.model';
import {
  ButtonSize,
  ButtonType,
} from 'src/app/visual-components/components/ff-button/ff-button.component';
import { BaseQuestion } from 'src/app/models/Question';

@Component({
  selector: 'question-comment',
  templateUrl: './question-comment.component.html',
})
export class QuestionCommentComponent implements OnInit {
  maxCharacterLimit: number = 100;
  commentContent: number = 0;
  showOtherReasonTextBox: boolean = false;
  reason: typeof Reason = Reason;
  buttonSizeEnum: typeof ButtonSize = ButtonSize;
  buttonTypeEnum: typeof ButtonType = ButtonType;
  options: any[] = [
    { value: Reason.VerifyInfo, displayName: 'Recheck Information' },
    { value: Reason.MoreInfo, displayName: 'More Information Required' },
    { value: Reason.UploadError, displayName: 'Wrong File Uploaded' },
    { value: Reason.Other, displayName: 'Other' },
  ];

  @Input() question!: BaseQuestion;
  @Output() saveComment = new EventEmitter<OnCommentRequest>();
  @Output() closeComment = new EventEmitter<void>();

  public commentForm = new FormGroup({
    reason: new FormControl(this.reason.VerifyInfo),
    text: new FormControl('', [Validators.maxLength(100)]),
    otherReason: new FormControl(''),
  });

  ngOnInit() {
    this.commentForm.get('reason')?.valueChanges.subscribe((value) => {
      if (value === this.reason.Other) {
        this.showOtherReasonTextBox = true;
      } else {
        this.showOtherReasonTextBox = false;
      }
    });
  }

  save(): void {
    if (!this.commentForm.valid) {
      this.commentForm.markAllAsTouched();
      return;
    }

    this.saveComment.emit(
      new OnCommentRequest(
        new CreateCommentThreadRequest(
          this.question,
          this.commentForm.value.reason!,
          this.commentForm.value.otherReason,
          this.commentForm.value.text!,
          undefined,
        ),
      ),
    );
  }

  close(): void {
    this.closeComment.emit();
  }

  keyUp(value: string): void {
    this.commentContent = value.length;
  }
}
