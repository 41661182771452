import { Component, Input } from '@angular/core';
import { FFComponentBase } from '../ff-base/ff-component.base';
import {
  VisualType,
  VisualMode,
  VisualMapping,
  ShapeType,
} from 'src/app/models/Visual';

@Component({
  selector: 'ff-visualbox',
  templateUrl: './ff-visualbox.component.html',
})
export class FFVisualboxComponent extends FFComponentBase {
  @Input() value?: string[];
  @Input() visualType?: VisualType;
  @Input() visualMode?: VisualMode;
  @Input() visualMappings?: VisualMapping[];
  @Input() imageWidth?: number;
  @Input() shapeType?: ShapeType;
  VisualMode = VisualMode;
  VisualType = VisualType;
  ShapeType = ShapeType;

  getMappingVisualValue(value: string): string | undefined {
    const visualMapping = this.visualMappings?.find(
      (mapping) => mapping.value === value,
    );
    return visualMapping ? visualMapping.visualValue : undefined;
  }
}
