<div>
  <div *ngIf="showQuestionInfo" class="mb-2">
    <ff-label
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
      [showTitle]="showTitle"
      [controlId]="id"
    />
    <ng-content select="[commentIcon]" />
  </div>

  <ff-subtitle *ngIf="showSubtitle" [subtitle]="subtitle" />

  <div>
    <button
      ff-button
      [type]="type"
      [primaryButton]="buttonType === 'btn-primary'"
      [class]="buttonType"
      [ngbTooltip]="buttonTooltip ? textValue : null"
      [disabled]="!enabled"
      class="text-truncate"
      [ngClass]="{
        'border border-danger-subtle border-4': invalid,
        'btn-sm fw-semibold': buttonSize === 'btn-sm',
        'btn-md': buttonSize === 'btn-md',
        'btn-lg': buttonSize === 'btn-lg'
      }"
      [style.width]="'auto !important'"
      [id]="id"
      (click)="onClickEvent()"
    >
      <ng-content select="[Icon]" />
      {{ textValue }}
    </button>
  </div>
</div>
