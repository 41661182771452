import { Pipe } from '@angular/core';
import { BaseFFxDatePipe } from './base-ffx-date.pipe';

@Pipe({
  name: 'shortTimePipe',
})
export class ShortTimePipe extends BaseFFxDatePipe {
  override get formatString(): string {
    return 'shortTime';
  }
}
