import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FFInputComponentBase } from '../ff-base/ff-input-component-base';
import { KeyValuePair, KeyValuePairDisplay } from 'src/app/models/Lookup';
import { OverlayListenerOptions, OverlayOptions } from 'primeng/api';

@Component({
  selector: 'ff-lookup',
  templateUrl: './ff-lookup.component.html',
})
export class FFLookupComponent
  extends FFInputComponentBase
  implements OnInit, OnChanges
{
  @Output() lookupDropdownShow: EventEmitter<void> = new EventEmitter<void>();

  @Input() options: KeyValuePair[] = [];
  @Input() filter: boolean = true;
  @Input() showClear: boolean = true;

  optionsLoaded: boolean = false;
  displayOptions: KeyValuePairDisplay[] = [];

  override ngOnInit() {
    super.ngOnInit();
    this.formatValue(this.formControl.value, false, true);
    this.setInitialOption(false);
    this.formControl.valueChanges.subscribe((value: any) => {
      this.formatValue(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      setTimeout(() => {
        this.displayOptions = this.formatOptions(this.options);
        if (!this.isValueInOptions(this.formControl.value)) {
          this.formControl.setValue(null);
          this.onChangeEvent(null);
        }
      }, 0);
    }
  }

  getOverlayOptions(): OverlayOptions {
    return {
      listener: (event: Event, options?: OverlayListenerOptions) => {
        if (event.type === 'scroll') {
          return false;
        }
        if (options?.type === 'outside' && !options?.valid) {
          return false;
        }
        return true;
      },
    };
  }

  onShow(): void {
    this.optionsLoaded = true;
    this.lookupDropdownShow.emit();
  }

  isValueInOptions(value: KeyValuePairDisplay | string | null): boolean {
    if (!value || typeof value === 'string') {
      return true;
    }
    return this.options.some((option) => option.key === value.key);
  }

  onClear(): void {
    if (!this.optionsLoaded) {
      this.options = [];
      this.displayOptions = [];
    }
  }

  formatValue(
    value: any,
    formatOptions: boolean = true,
    initialLoad = false,
  ): void {
    // Handle KeyValuePair
    if (KeyValuePair.isKeyValuePair(value)) {
      this.formControl.patchValue(
        new KeyValuePairDisplay(value.key, value.value),
        { emitEvent: false },
      );
      if (!this.optionsLoaded) {
        this.setInitialOption(formatOptions);
      }
      return;
    }

    // Handle KeyValuePairDisplay
    if (KeyValuePairDisplay.isKeyValuePairDisplay(value)) {
      this.onChangeEvent(
        new KeyValuePair(
          this.formControl.value.key,
          this.formControl.value.value,
        ),
      );
      return;
    }

    // Handle initial load
    if (initialLoad) {
      this.formControl.patchValue(null, { emitEvent: false });
    } else {
      this.onChangeEvent(null);
    }
  }

  formatOptions(options: KeyValuePair[]): KeyValuePairDisplay[] {
    return options.map((pair) => new KeyValuePairDisplay(pair.key, pair.value));
  }

  setInitialOption(formatOptions: boolean = true): void {
    this.options =
      this.formControl.value != null
        ? [
            new KeyValuePair(
              this.formControl.value.key,
              this.formControl.value.value,
            ),
          ]
        : [];
    if (formatOptions) {
      this.displayOptions = this.formatOptions(this.options);
    }
  }
}
