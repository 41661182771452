import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FFInputComponentBase } from '../ff-base/ff-input-component-base';

@Component({
  selector: 'ff-upload-drag-and-drop',
  templateUrl: './ff-upload-drag-and-drop.component.html',
  styleUrls: ['./ff-upload-drag-and-drop.component.scss'],
})
export class FFUploadDragAndDropComponent extends FFInputComponentBase {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @Input() fileSizeLimit?: number | null;
  @Input() readOnly = false;

  onFileDrop(event: DragEvent): void {
    event.preventDefault();

    // ignore file drop events if the component is read only or disabled
    if (!this.readOnly && this.enabled) {
      this.onChangeEvent(event);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  change(event: Event): void {
    if (!this.readOnly && this.enabled) {
      this.onChangeEvent(event);
    }
  }
}
