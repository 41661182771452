import { Component, Input, OnInit } from '@angular/core';
import { FFInputComponentBase } from '../ff-base/ff-input-component-base';
import { NgControl } from '@angular/forms';
import {
  VisualType,
  VisualMode,
  VisualMapping,
  ShapeType,
} from 'src/app/models/Visual';

@Component({
  selector: 'ff-radio',
  templateUrl: './ff-radio.component.html',
  styleUrls: ['./ff-radio.component.scss'],
  providers: [{ provide: NgControl, useValue: NgControl }],
})
export class FFRadioComponent extends FFInputComponentBase implements OnInit {
  @Input() options?: any[];
  @Input() defaultValue?: string;
  @Input() horizontalLayout?: boolean = false;
  @Input() visualType?: VisualType;
  @Input() visualMode?: VisualMode;
  @Input() visualMappings?: VisualMapping[];
  @Input() imageWidth?: number;
  @Input() shapeType?: ShapeType;
  VisualMode = VisualMode;
  VisualType = VisualType;
  ShapeType = ShapeType;

  override ngOnInit(): void {
    super.ngOnInit();
    this.formControl.patchValue(this.control.value ?? this.defaultValue);
  }

  override onChangeEvent(): void {
    super.onChangeEvent(this.formControl.value);
  }

  getMappingVisualValue(value: string): string | undefined {
    const visualMapping = this.visualMappings?.find(
      (mapping) => mapping.value === value,
    );
    return visualMapping ? visualMapping.visualValue : undefined;
  }
}
