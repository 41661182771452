import { Directive, Injector, Input } from '@angular/core';
import { FFInputComponentBase } from './ff-input-component-base';
import { NgControl } from '@angular/forms';
import { map } from 'rxjs/operators';

@Directive()
export class FFDateAndTimeComponentBase extends FFInputComponentBase {
  constructor(public injector: Injector) {
    super(injector.get(NgControl));
  }

  touchStarted: boolean = false;

  @Input() value?: string | null;

  /**
   * @description Overrides the ngOnInit so it can patch the formValue
   * @returns { void } The return type description
   */
  override ngOnInit(): void {
    super.ngOnInit();

    // Subscribe to value changes and format the value
    this.formControl.valueChanges
      .pipe(map((value: string | null) => this.formatDate(value)))
      .subscribe((formattedValue) => {
        this.formControl.patchValue(formattedValue, { emitEvent: false });
      });

    if (this.value) this.formControl.patchValue(this.value);
  }

  /**
   * @description On blur of date/time component will patch value on form and propagate changes
   * It will also set the type of input of control if theres a value
   * @param { FocusEvent } event The event that caused the function to trigger
   * @param { string } type The type to set the input control
   * @returns { void } The return type description
   */
  onBlur(event: FocusEvent, type: string): void {
    const inputElementTarget = event.target as HTMLInputElement;
    if (inputElementTarget.value) {
      inputElementTarget.type = type;
    } else {
      inputElementTarget.type = 'text';
    }

    this.onChangeEvent(this.toIsoString(this.formControl.value));
  }

  /**
   * @description on "touchstart" event will set the touchStarted to true
   * @param { TouchEvent } event The event that caused the function to trigger
   * @returns { void } The return type description
   */
  onTouchStart(event: TouchEvent): void {
    this.touchStarted = true;
  }

  /**
   * @description on "touchmove" event will set the touchStarted to false
   * @param { TouchEvent } event The event that caused the function to trigger
   * @returns { void } The return type description
   */
  onTouchMove(event: TouchEvent): void {
    this.touchStarted = false;
  }

  /**
   * @description on "touchend" event will set the type of input control and focus on it depending on the touchStarted value
   * It will also reset the touchStarted value to false
   * @param { TouchEvent } event The event that caused the function to trigger
   * @param { string } type The type to set the input control
   * @returns { void } The return type description
   */
  onTouchEnd(event: TouchEvent, type: string): void {
    if (this.touchStarted) {
      const inputElement = event.target as HTMLInputElement;
      inputElement.type = type;
      inputElement.focus();
    }
    this.touchStarted = false;
  }

  /**
   * @description Used to format values to form control value
   * @param { string | null } value The value to format
   * @returns { string | null } Returns formatted value or null
   */
  formatDate(value: string | null): string | null {
    return value;
  }

  /**
   * Checks if the value is a valid date time
   * @param value Value to validate
   * @returns If value is a valid date time
   */
  isValidDateTime(value: string): boolean {
    return !isNaN(new Date(value).getTime());
  }

  toIsoString(value: string | null): string | null {
    if (!value) {
      return null;
    }

    if (!this.isValidDateTime(value))
      throw new Error('Invalid date and time: ' + value);

    const date = new Date(value);
    return date.toISOString();
  }
}
