import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError, share } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { IFlow } from '../../../models/IFlow';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { TableQuestion } from '../../../models/TableQuestion';
import { FFxUser } from '../../../models/PersonOrGroup';
import { Feedback } from '../../../models/Feedback';
import {
  Comment,
  CommentReplyRequest,
  CreateCommentThreadDto,
} from '../../../components/questions/question-comment/question-comment.model';
import { BaseQuestion } from '../../../models/Question';
import { FormHistory } from '../../../models/FormHistory';
import { AffectedQuestion } from '../../../models/AffectedQuestion';
import { FileUploadDetails } from 'src/app/models/FileUpload';
import { FormActions } from 'src/app/models/FormActions';

@Injectable({
  providedIn: 'root',
})
export class FlowService {
  private _domain: string = environment.ffxFlowApiUrl;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  get = (flowId: number, formId: number, isNew: boolean): Observable<IFlow> => {
    const url = `${this._domain}/Flow/${flowId}/Form/${formId}?isNew=${isNew}`;
    return this._http.get<IFlow>(url, this.httpOptions).pipe(share());
  };

  patchFlow = (
    patchFlowRequest: PatchFlowRequest,
  ): Observable<PatchFlowEventResponse> => {
    return this._http
      .patch<PatchFlowEventResponse>(
        `${this._domain}/Flow`,
        patchFlowRequest,
        this.httpOptions,
      )
      .pipe(catchError(this.handleError));
  };

  submitStep = (
    submitStepRequest: SubmitStepRequest,
  ): Observable<PatchFlowEventResponse> => {
    const formData = new FormData();
    formData.append('flowId', submitStepRequest.flowId);
    formData.append('stepId', submitStepRequest.stepId);
    formData.append(
      'ruleTriggerEvent',
      submitStepRequest.ruleTriggerEvent.toString(),
    );
    submitStepRequest.files?.forEach((file) => {
      formData.append('files', file);
    });

    return this._http
      .patch<PatchFlowEventResponse>(`${this._domain}/Flow/Step`, formData)
      .pipe(catchError(this.handleError));
  };

  submitQuestion = (
    submitQuestionRequest: SubmitQuestionRequest,
  ): Observable<PatchFlowEventResponse> => {
    if (submitQuestionRequest.files && submitQuestionRequest.files.length > 0) {
      const formData = new FormData();
      formData.append('flowId', submitQuestionRequest.flowId);
      formData.append('questionId', submitQuestionRequest.questionId);
      formData.append(
        'ruleTriggerEvent',
        submitQuestionRequest.ruleTriggerEvent.toString(),
      );
      formData.append('newValue', submitQuestionRequest.newValue);
      submitQuestionRequest.files.forEach((file: File) => {
        formData.append('files', file);
      });

      return this._http
        .patch<PatchFlowEventResponse>(
          `${this._domain}/Flow/Question/multipart`,
          formData,
        )
        .pipe(catchError(this.handleError));
    }
    return this._http
      .patch<PatchFlowEventResponse>(
        `${this._domain}/Flow/Question/json`,
        submitQuestionRequest,
        this.httpOptions,
      )
      .pipe(catchError(this.handleError));
  };

  delegateStep = (
    flowId: string,
    delegateStepRequest: DelegateStepRequest,
  ): Observable<IFlow> => {
    return this._http.post<IFlow>(
      `${this._domain}/Flow/${flowId}/Step/Delegate`,
      delegateStepRequest,
      this.httpOptions,
    );
  };

  submitStepFeedback = (
    flowId: string,
    stepId: string,
    stepFeedbackRequest: StepFeedbackRequest,
  ): Observable<IFlow> => {
    return this._http.post<IFlow>(
      `${this._domain}/Flow/${flowId}/Step/${stepId}/Feedback`,
      stepFeedbackRequest,
      this.httpOptions,
    );
  };

  submitStepNote = (
    flowId: string,
    stepId: string,
    stepNoteRequest: StepNoteRequest,
  ): Observable<IFlow> => {
    return this._http.patch<IFlow>(
      `${this._domain}/Flow/${flowId}/Step/${stepId}/Note`,
      stepNoteRequest,
      this.httpOptions,
    );
  };

  generateTableCsv(flowId: string, questionId: string): Observable<Blob> {
    return this._http.get(
      `${this._domain}/Flow/${flowId}/Question/${questionId}/ExportTable`,
      { responseType: 'blob' },
    );
  }

  tableOperation(
    tableOperationRequest: TableOperationRequest,
  ): Observable<PatchFlowEventResponse> {
    if (
      tableOperationRequest.files &&
      tableOperationRequest.files.length > 0 &&
      tableOperationRequest.cellId
    ) {
      const formData = new FormData();
      formData.append('flowId', tableOperationRequest.flowId);
      formData.append(
        'ruleTriggerEvent',
        tableOperationRequest.ruleTriggerEvent.toString(),
      );
      formData.append('tableQuestionId', tableOperationRequest.tableQuestionId);
      formData.append('value', '');
      formData.append(
        'operationType',
        tableOperationRequest.operationType.toString(),
      );
      formData.append('cellId', tableOperationRequest.cellId);
      tableOperationRequest.files.forEach((file: File) => {
        formData.append('files', file);
      });

      return this._http
        .patch<PatchFlowEventResponse>(
          `${this._domain}/Flow/Question/Table/multipart`,
          formData,
        )
        .pipe(catchError(this.handleError));
    }
    return this._http
      .patch<PatchFlowEventResponse>(
        `${this._domain}/Flow/Question/Table/json`,
        tableOperationRequest,
        this.httpOptions,
      )
      .pipe(catchError(this.handleError));
  }

  getFormHistory(flowId: string): Observable<FormHistory> {
    return this._http.get<FormHistory>(
      `${this._domain}/Flow/${flowId}/FormHistory`,
      this.httpOptions,
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`,
      );
    }
    // return an observable with a user-facing error message
    return throwError(
      () => new Error('Something bad happened; please try again later.'),
    );
  }

  reopenStep = (
    flowId: string,
    stepId: string,
    reopenRequest: StepReopenRequest,
  ): Observable<IFlow> => {
    return this._http.patch<IFlow>(
      `${this._domain}/Flow/${flowId}/Step/${stepId}/ReOpen`,
      reopenRequest,
      this.httpOptions,
    );
  };

  reopenForm = (
    flowId: string,
    reopenRequest: FormReopenRequest,
  ): Observable<IFlow> => {
    return this._http.patch<IFlow>(
      `${this._domain}/Flow/${flowId}/Reopen`,
      reopenRequest,
      this.httpOptions,
    );
  };

  stepAttachmentUpload = (
    flowId: string,
    stepId: string,
    stepAttachmentUploadRequest: StepAttachmentUploadRequest,
  ): Observable<IFlow> => {
    const formData = new FormData();
    stepAttachmentUploadRequest.files.forEach((file: File) => {
      formData.append('files', file);
    });
    return this._http.patch<IFlow>(
      `${this._domain}/Flow/${flowId}/Step/${stepId}/Attachments`,
      formData,
    );
  };

  commentReply = (
    flowId: string,
    questionId: string,
    comment: CommentReplyRequest,
  ): Observable<BaseQuestion> => {
    return this._http.post<BaseQuestion>(
      `${this._domain}/Flow/${flowId}/Question/${questionId}/Comment`,
      comment,
      this.httpOptions,
    );
  };

  stepAttachmentDelete = (
    flowId: string,
    stepId: string,
    attachmentId: string,
  ): Observable<IFlow> => {
    return this._http.delete<IFlow>(
      `${this._domain}/Flow/${flowId}/Step/${stepId}/Attachments/${attachmentId}`,
    );
  };

  flowDelete(flowId: string): Observable<IFlow> {
    return this._http.delete<IFlow>(`${this._domain}/Flow/${flowId}`);
  }

  deleteQuestionComment = (
    flowId: string,
    questionId: string,
    id: string,
  ): Observable<BaseQuestion> => {
    return this._http.delete<BaseQuestion>(
      `${this._domain}/Flow/${flowId}/Question/${questionId}/Comment/${id}`,
    );
  };

  editQuestionComment = (
    flowId: string,
    questionId: string,
    commentDto: Comment,
  ): Observable<BaseQuestion> => {
    return this._http.patch<BaseQuestion>(
      `${this._domain}/Flow/${flowId}/Question/${questionId}/Comment`,
      commentDto,
    );
  };

  constructor(
    private _http: HttpClient,
    private _route: ActivatedRoute,
  ) {}
}

export class TriggerRuleEventRequest {
  /**
   *
   */
  constructor(
    public flowId: string,
    public stepId?: string,
    public questionId?: string,
    public newValue?: any,
    public ruleTriggerEvent?: RuleTriggerEvent,
  ) {}
}

export class PatchFlowRequest {
  /**
   *
   */
  constructor(
    public flowId: string,
    public ruleTriggerEvent: RuleTriggerEvent,
    public files?: File[],
  ) {}
}

export class SubmitStepRequest {
  /**
   *
   */
  constructor(
    public flowId: string,
    public stepId: string,
    public ruleTriggerEvent: RuleTriggerEvent,
    public files?: File[],
  ) {}
}

export class SubmitQuestionRequest {
  /**
   *
   */
  constructor(
    public flowId: string,
    public questionId: string,
    public ruleTriggerEvent: RuleTriggerEvent,
    public newValue?: any,
    public files?: File[],
  ) {}
}

export class DelegateStepRequest {
  constructor(
    public peopleOrGroup: FFxUser[],
    public comment?: string,
  ) {}
}

export class StepFeedbackRequest {
  constructor(public stepFeedback: Feedback) {}
}

export class TableOperationRequest {
  /**
   *
   */
  constructor(
    public flowId: string,
    public operationType: TableOperationType,
    public ruleTriggerEvent: RuleTriggerEvent,
    public tableQuestionId: string,
    public value?: number | string | string[],
    public cellId?: string,
    public files?: File[],
  ) {}
}

export class StepReopenRequest {
  constructor(
    public dueDate: Date,
    public commentThreads: CreateCommentThreadDto[],
  ) {}
}

export class FormReopenRequest {
  constructor(
    public stepId: string,
    public reason: string,
  ) {}
}

export class StepNoteRequest {
  constructor(
    public text: string,
    public createdDate: Date,
    public id?: string,
  ) {}
}

export class StepAttachmentUploadRequest {
  constructor(public files: File[]) {}
}

export class PatchFlowEventResponse {
  flow!: IFlow;
  affectedQuestions?: AffectedQuestion[];
  formActions?: FormActions[];
}

export class PatchFlowTableEventResponse extends PatchFlowEventResponse {
  operationType!: TableOperationType;
  tableQuestion!: TableQuestion;
  files?: File[];
  invalidFileUploadDetails?: FileUploadDetails[];
  cellId?: string;
  rowId?: string;
}

export enum RuleTriggerEvent {
  // Question level events
  QuestionUpdated = 'QuestionUpdated',

  // Step level events
  StepCompleting = 'StepCompleting',
  StepStarting = 'StepStarting',
  StepSaving = 'StepSaving',

  // Form level events
  FormCompleted = 'FormCompleted',
  FormStarted = 'FormStarted',
  FormLoaded = 'FormLoaded',
  FormRejected = 'FormRejected',
}

export enum TableOperationType {
  AddRow = 'AddRow',
  RemoveRow = 'RemoveRow',
  DuplicateRow = 'DuplicateRow',
  QuestionUpdated = 'QuestionUpdated',
}

export enum TableViewMode {
  Card = 'Card',
  Grid = 'Grid',
}
