<div class="row m-2">
  <!-- FF Button -->
  <div class="col-6">
    <div class="card">
      <div
        class="card-header bg-primary-subtle text-center"
        (click)="callRuleApi()"
      >
        FF Button
      </div>
      <div class="card-body">
        <h5 class="card-title">FF Button</h5>

        <p class="card-text"></p>

        <div class="row mb-2 border-bottom">
          <div class="col-2"></div>
          <div class="col">Primary</div>
          <div class="col">Secondary</div>
          <div class="col">Danger/Delete</div>
        </div>

        <div class="row">
          <div class="col-2 align-self-center">Default Hover Pressed</div>
          <div class="col">
            <ffx-button
              [enabled]="true"
              label="Default Primary"
              tooltip="Description of Primary Btn"
              textValue="Primary Btn"
              [showTitle]="true"
              [required]="true"
              [buttonType]="primary"
            />
          </div>
          <div class="col">
            <ffx-button
              [enabled]="true"
              label="Default Secondary"
              tooltip="Description of Secondary Btn"
              textValue="Secondary Btn"
              [showTitle]="true"
              [required]="true"
              [buttonType]="secondary"
            />
          </div>
          <div class="col">
            <ffx-button
              [enabled]="true"
              label="Default Danger"
              tooltip="Description of Danger Btn"
              textValue="Danger Btn"
              [showTitle]="true"
              [required]="true"
              [buttonType]="danger"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-2 align-self-center">Disabled</div>
          <div class="col">
            <ffx-button
              [enabled]="false"
              label="Disabled Primary"
              tooltip="Description of Primary Btn"
              textValue="Primary Btn"
              [showTitle]="true"
              [required]="true"
              [buttonType]="primary"
            />
          </div>
          <div class="col">
            <ffx-button
              [enabled]="false"
              label="Disabled Secondary"
              tooltip="Description of Secondary Btn"
              textValue="Secondary Btn"
              [showTitle]="true"
              [required]="true"
              [buttonType]="secondary"
            />
          </div>
          <div class="col">
            <ffx-button
              [enabled]="false"
              label="Disabled Danger"
              tooltip="Description of Danger Btn"
              textValue="Danger Btn"
              [showTitle]="true"
              [required]="true"
              [buttonType]="danger"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-2 align-self-center">Error</div>
          <div class="col">
            <ffx-button
              [enabled]="true"
              label="Error Primary"
              tooltip="Description of Primary Btn"
              textValue="Primary Btn"
              [showTitle]="true"
              [required]="true"
              [buttonType]="primary"
              [invalid]="true"
            />
          </div>
          <div class="col">
            <ffx-button
              [enabled]="true"
              label="Error Secondary"
              tooltip="Description of Secondary Btn"
              textValue="Secondary Btn"
              [showTitle]="true"
              [required]="true"
              [buttonType]="secondary"
              [invalid]="true"
            />
          </div>
          <div class="col">
            <ffx-button
              [enabled]="true"
              label="Error Danger"
              tooltip="Description of Danger Btn"
              textValue="Danger Btn"
              [showTitle]="true"
              [required]="true"
              [buttonType]="danger"
              [invalid]="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- FF Input -->
  <div class="col">
    <div class="card">
      <div class="card-header bg-primary-subtle text-center">FF Input</div>
      <div class="card-body">
        <h5 class="card-title">FF Input</h5>
        <p class="card-text">
          With supporting text below as a natural lead-in to additional content.
        </p>

        <form class="needs-validation" novalidate #form>
          <div class="mb-3">
            <label class="form-label fw-semibold" for="empty"
              >Empty<i
                class="bi bi-info-circle ms-1 text-secondary"
                ngbTooltip="Content of tooltip"
                triggers="click:blur"
              ></i
            ></label>
            <input
              type="text"
              class="form-control d-flex rounded-1"
              id="empty"
            />
            <!-- light is readonly -->
          </div>

          <div class="mb-3">
            <label class="form-label fw-semibold" for="focus">Focus</label>
            <!-- <input type="text" class="form-control d-flex rounded-1 border-dark-subtle" id="title">  -->
            <input
              type="text"
              class="form-control d-flex rounded-1"
              id="focus"
            />
          </div>

          <div class="mb-3">
            <label for="error" class="form-label fw-semibold">Error</label>
            <div class="invalid-feedback" for="error">
              Please insert a value for error.
            </div>
            <input
              type="text"
              class="form-control d-flex rounded-1"
              required
              id="error"
            />
            <!-- <div class="valid-feedback">
            Please choose a username is fine.
          </div> -->
          </div>

          <label for="readonly" class="form-label fw-semibold">Read Only</label>
          <input
            type="text"
            class="form-control d-flex rounded-1"
            id="readonly"
            disabled
          />

          <!-- <form>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Email address</label>
            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
            <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">Password</label>
            <input type="password" class="form-control" id="exampleInputPassword1">
          </div>
          <div class="mb-3 form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1">
            <label class="form-check-label" for="exampleCheck1">Check me out</label>
          </div>
          <button type="submit" class="btn btn-primary">Submit</button>
        </form> -->

          <br />
          <input type="submit" value="Submit" />
          <button
            ffx-button-primary
            class="ms-1"
            (click)="form.classList.add('was-validated')"
          >
            Validate
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="row m-2">
  <div class="col">
    <div class="card">
      <div class="card-header bg-primary-subtle text-center">FF Separator</div>

      <div class="card-body">
        <h5 class="card-title">FF Separator</h5>
        <p class="card-text">
          Custom separator component for solid separator line
        </p>

        <!-- line only separator -->
        <ff-separator></ff-separator>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
      <div class="card-header bg-primary-subtle text-center">
        FF Input Number
      </div>
      <div class="card-body">
        <h5 class="card-title">FF Input Number</h5>
        <p class="card-text">Custom input component for numbers</p>
        <ff-input-number
          [label]="'Empty'"
          placeHolder="Enter number"
          [enabled]="true"
          [showTitle]="true"
          [required]="true"
        ></ff-input-number>
        <ff-input-number
          [label]="'Normal'"
          [minimum]="0"
          [maximum]="20"
          [enabled]="true"
          [showTitle]="true"
          [required]="true"
        ></ff-input-number>
        <ff-input-number
          [label]="'Error'"
          [minimum]="0"
          [maximum]="20"
          [enabled]="true"
          [showTitle]="true"
          [required]="true"
        ></ff-input-number>
        <ff-input-number
          [label]="'2 Decimal Places'"
          [decimalPlaces]="2"
          [minimum]="0"
          [maximum]="20"
          [enabled]="true"
          [showTitle]="true"
          [required]="true"
        ></ff-input-number>
        <ff-input-number
          [label]="'Disabled'"
          [minimum]="0"
          [maximum]="20"
          [enabled]="false"
          [showTitle]="true"
          [required]="true"
        ></ff-input-number>
      </div>
    </div>
  </div>
</div>

<div class="row m-2">
  <div class="col">
    <div class="card">
      <div class="card-header bg-primary-subtle text-center">
        FF Input Number with Slider
      </div>
      <div class="card-body">
        <h5 class="card-title">FF Input Number with Slider</h5>
        <p class="card-text">
          Custom input component for numbers with a slider control
        </p>
        <ff-input-number-slider
          [label]="'Empty'"
          placeHolder="Enter number"
          [enabled]="true"
          [showTitle]="true"
          [required]="true"
        ></ff-input-number-slider>
        <ff-input-number-slider
          [label]="'Normal'"
          [minimum]="0"
          [maximum]="20"
          [step]="1"
          [enabled]="true"
          [showTitle]="true"
          [required]="true"
        ></ff-input-number-slider>
        <ff-input-number-slider
          [label]="'Error'"
          [minimum]="0"
          [maximum]="15"
          [step]="1"
          [enabled]="true"
          [showTitle]="true"
          [required]="true"
        ></ff-input-number-slider>
        <ff-input-number-slider
          [label]="'2 Decimal Places'"
          [minimum]="0"
          [maximum]="15"
          [step]="0.01"
          [decimalPlaces]="2"
          [enabled]="true"
          [showTitle]="true"
          [required]="true"
        ></ff-input-number-slider>
        <ff-input-number-slider
          [label]="'Default Value'"
          [minimum]="-5"
          [maximum]="20"
          [step]="2"
          [enabled]="false"
          [showTitle]="true"
          [required]="true"
        ></ff-input-number-slider>
      </div>
    </div>
  </div>

  <!-- Message divider -->
  <div class="col">
    <div class="card">
      <div class="card-header bg-primary-subtle text-center">
        FF Message Divider
      </div>

      <div class="card-body">
        <h5 class="card-title">FF Message Divider</h5>
        <p class="card-text">Custom component for message divider</p>

        <ff-message-divider
          message="A simple success message - check it out!"
          [alertType]="alertTypeEnum.Success"
          title="Success"
        ></ff-message-divider>
        <ff-message-divider
          message="A simple alert message  - check it out!"
          [alertType]="alertTypeEnum.Alert"
          title="Alert"
        ></ff-message-divider>
        <ff-message-divider
          message="A simple information message  - check it out!"
          [alertType]="alertTypeEnum.Information"
          title="Info"
        ></ff-message-divider>
        <ff-message-divider
          message="Tuesday, December 20, 2022"
          [alertType]="alertTypeEnum.StepSubmittedHistory"
          title="Completed"
          stepSubmittedBy="John Doe"
        ></ff-message-divider>
      </div>
    </div>
  </div>
</div>

<div class="row m-2">
  <!-- File upload -->
  <div class="col">
    <div class="card">
      <div class="card-header bg-primary-subtle text-center">
        FF upload list file item
      </div>

      <div class="card-body">
        <h5 class="card-title">FF upload list file item</h5>
        <p class="card-text">Custom component for upload list file item</p>
        <ff-upload-list-files
          [savedfiles]="listItemsWithoutPreviews"
        ></ff-upload-list-files>
      </div>
    </div>
  </div>

  <div class="col">
    <div class="card">
      <div class="card-header bg-primary-subtle text-center">
        FF upload list image item
      </div>

      <div class="card-body">
        <h5 class="card-title">FF upload list image item</h5>
        <p class="card-text">Custom component for upload list image item</p>
        <ff-upload-list-images
          [files]="listItemsWithPreviews"
        ></ff-upload-list-images>
      </div>
    </div>
  </div>
</div>

<div class="row m-2">
  <!-- Title description  -->
  <div class="col">
    <div class="card">
      <div class="card-header bg-primary-subtle text-center">
        FF Title and description
      </div>

      <div class="card-body">
        <h5 class="card-title">FF Title and description</h5>
        <p class="card-text">Custom component for title and description</p>

        <ff-title-description
          title="Title"
          description="Description"
        ></ff-title-description>
      </div>
    </div>
  </div>

  <div class="col">
    <div class="card">
      <div class="card-header bg-primary-subtle text-center">
        Image annotation
      </div>

      <ff-image-annotation
        [brushColor]="'#FF0000'"
        [imageWidth]="800"
        [imageHeight]="600"
        [isDrawingMode]="true"
      ></ff-image-annotation>
    </div>
  </div>
</div>

<div class="row m-2">
  <!-- Date and time -->
  <div class="col">
    <div class="card">
      <div class="card-header bg-primary-subtle text-center">
        FF Date and time
      </div>

      <div class="card-body">
        <h5 class="card-title">FF Date and time</h5>
        <p class="card-text">Custom component for date and time</p>
        <ff-date-and-time placeHolder="Select Date And Time" />
        <h5 class="card-title">FF Date only</h5>
        <p class="card-text">Custom component for date</p>
        <ff-date-only placeHolder="Select Date And Time" />
        <h5 class="card-title">FF Time only</h5>
        <p class="card-text">Custom component for time</p>
        <ff-time-only placeHolder="Select Date And Time" />
      </div>
    </div>
  </div>

  <div class="col">
    <!-- Space for new component -->
    <div class="card">
      <div class="card-header bg-primary-subtle text-center">
        FF Signature Pad
      </div>

      <ff-signature-pad></ff-signature-pad>
    </div>
  </div>
</div>

<div class="row m-2">
  <div class="col-6">
    <div class="card">
      <div class="card-header bg-primary-subtle text-center">
        FF MultiSelect
      </div>
      <div class="card-body">
        <h5 class="card-title">FF MultiSelect</h5>
        <p class="card-text">Custom component for multi select</p>
        <div [formGroup]="myGroup">
          <ff-multiselect
            formControlName="msSample"
            id="msSample"
            label="Multi Select Sample"
            [options]="msOptions"
            (OnChange)="onChangeEvent($event)"
            [placeHolder]="'Please select'"
          >
          </ff-multiselect>
        </div>
      </div>
    </div>
  </div>
</div>
