import { Question } from './Question';

export class FileUpload extends Question<FileUploadDetails[]> {
  fileSizeLimit?: number;
  extensions?: string[];
  fileExtensionPermissionType?: FileExtensionPermissionType;
  showImagePreview?: boolean;
  annotateImage?: boolean;
  annotationColor?: string;
  hideUploadButton?: boolean;
  hideUploadLink?: boolean;
  allowMultipleFiles: boolean = false;
}

export class FileUploadDetails {
  constructor(displayName: string) {
    this.displayName = displayName;
  }
  id?: string;
  displayName: string;
  relativeFilePath?: string;
  storageType?: FileUploadStorageType;
  fileSize?: number;
  errorMessages?: FileError[];
}

export class FileError {
  constructor(type: FileValidationErrorType, message: string) {
    this.type = type;
    this.message = message;
  }
  type: FileValidationErrorType;
  message: string;
}

export enum FileUploadStorageType {
  SharePoint = 'SharePoint',
  Azure = 'AzureBlobStorage',
}

export enum FileExtensionPermissionType {
  Allowed = 'Allow',
  Disallowed = 'Disallow',
}

export enum FileAction {
  Delete,
  Upload,
}

export enum FileValidationErrorType {
  Extension = 'Extension',
  Size = 'Size',
  Name = 'Name',
}
