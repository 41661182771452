<form-card>
  <div class="d-flex flex-column gap-2">
    <ffx-button
      [buttonType]="buttonTypeEnum.Secondary"
      [id]="'WorkflowHistoryBackButton'"
      [textValue]="'Back'"
      [enabled]="true"
      [buttonSize]="buttonSizeEnum.Small"
      [buttonTooltip]="false"
      (OnClick)="returnBack()"
      ><i class="bi bi-arrow-left" Icon></i
    ></ffx-button>
    <div>
      <ff-text
        class="d-block fs-4 fw-medium my-3"
        [text]="'Form History'"
      ></ff-text>
      <form-name
        class="d-block"
        [flowTitle]="flowTitle"
        [formTitle]="flowFormTitle"
      ></form-name>
    </div>
  </div>
  <ff-separator></ff-separator>
  <div class="d-flex flex-row justify-content-between align-items-center">
    <a
      class="cursor-pointer text-decoration-none"
      (click)="sortEvents(displayedSortOptionEnum)"
    >
      <ff-text
        class="fs-7 text-primary align-self-center py-2"
        [text]="displayedSortOptionEnum"
        ><i class="bi bi-arrow-down-up me-2"></i
      ></ff-text>
    </a>
    <ffx-button
      [id]="'WorkflowHistoryToggleFilterButton'"
      class="py-2"
      [buttonType]="
        showFilter ? buttonTypeEnum.Primary : buttonTypeEnum.Secondary
      "
      [enabled]="true"
      [buttonSize]="buttonSizeEnum.Small"
      [buttonTooltip]="false"
      (OnClick)="toggleFilter()"
      ><i class="bi bi-filter-circle" Icon></i
    ></ffx-button>
  </div>
  <div
    [ngClass]="{
      'd-none': !showFilter,
      'd-flex': showFilter
    }"
    class="mb-3 d-flex flex-row justify-content-between gap-3 flex-column flex-md-row"
    [formGroup]="form"
  >
    <ff-multiselect
      formControlName="action"
      [id]="'WorkflowHistoryActionFilter'"
      class="w-100"
      [label]="'Filter by action'"
      [options]="actionOptions"
      [placeHolder]="'Please select'"
      (OnChange)="filterAndSortEvents()"
    ></ff-multiselect>
    <div class="w-100 d-flex flex-column">
      <ff-label
        [label]="'Filter by date'"
        [controlId]="'WorkflowHistoryStartDateEndDateFilters'"
      ></ff-label>
      <div class="w-100 d-flex flex-row align-items-center gap-2">
        <ff-date-only
          formControlName="startDate"
          [id]="'WorkflowHistoryStartDateFilter'"
          class="w-50"
          [placeHolder]="'Start date'"
          (OnChange)="filterAndSortEvents()"
        ></ff-date-only>
        <ff-date-only
          formControlName="endDate"
          [id]="'WorkflowHistoryEndDateFilter'"
          class="w-50"
          [placeHolder]="'End date'"
          (OnChange)="filterAndSortEvents()"
        ></ff-date-only>
      </div>
    </div>
    <ff-multiselect
      formControlName="user"
      [id]="'WorkflowHistoryUserFilter'"
      class="w-100"
      [label]="'Filter by user'"
      [placeHolder]="'Please select'"
      [options]="getEventUsers()"
      (OnChange)="filterAndSortEvents()"
    ></ff-multiselect>
  </div>
  <div
    class="position-relative pb-3 ps-4 workflow-block"
    *ngFor="let event of copyFlowEvents"
  >
    <div class="circle z-1 rounded-circle position-absolute"></div>
    <div class="border line h-100 position-absolute"></div>
    <div
      class="d-flex flex-column align-items-center border rounded fs-8 w-100"
    >
      <div class="d-flex flex-row justify-content-between w-100 px-3 pt-2">
        <ff-text class="text-black fw-bold" [text]="getEventText(event)"
          ><i class="bi me-1" [ngClass]="getEventIcon(event)"></i>
        </ff-text>
        <div class="d-flex align-items-center">
          <div>
            <i class="bi bi-person-circle h5 text-primary me-2"></i>
          </div>
          <ff-text class="fw-bold fs-8" [text]="getEventUser(event)"></ff-text>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-between w-100 px-3 pb-2">
        <ff-text [text]="getStepName(event)"></ff-text>
        <div class="text-secondary">
          {{ event.eventDate | mediumDateTimePipe }}
        </div>
      </div>
    </div>
  </div>
</form-card>
