<div class="card comment-thread alert-divider-information rounded-1">
  <div class="card-body">
    <div
      class="d-flex flex-column mt-2"
      *ngFor="
        let comment of commentThread?.comments;
        let i = index;
        first as showReason;
        last as isLast
      "
    >
      <div *ngIf="showReason" class="reason text-danger fw-bold d-flex mb-3">
        <i class="bi bi-exclamation-circle-fill"></i>
        <ff-text
          class="ms-2 reason-text"
          [text]="getReasonText(commentThread!.reason)"
        ></ff-text>
      </div>
      <div class="d-flex">
        <i class="bi bi-person-circle h5 text-primary me-2"></i>
        <div class="container-fluid">
          <p class="mb-0">
            <label class="text-secondary"> {{ comment.userDisplayName }}</label>
            <label class="ms-2 text-black-50">{{
              comment.timeStamp | relativeTime: currentTime : "ago"
            }}</label>
          </p>
          <p class="ms-2 text-black-50 small" *ngIf="comment.isEdited">
            Edited
          </p>
          <p class="mb-1 comment-text" *ngIf="!comment.enableEdit">
            {{ comment.text }}
          </p>
          <form
            *ngIf="comment.enableEdit"
            [formGroup]="editForm"
            class="mb-1 flex-grow-1 comment-textarea"
          >
            <ff-textarea
              [showTitle]="false"
              [autoSize]="false"
              [maxlength]="100"
              (OnKeyUp)="keyUp($event)"
              formControlName="text"
              [autoSize]="true"
              placeHolder="Edit Comments"
            ></ff-textarea>
            <p class="pt-1 fw-bold text-muted">
              {{ commentContent }}/{{ maxCharacterLimit }}
            </p>
          </form>
          <a
            *ngIf="comment.enableEdit"
            class="ms-2 edit-cancel link-secondary text-decoration-none cursor-pointer"
            (click)="onCancelEdit(i)"
            >Cancel</a
          >
          <a
            *ngIf="comment.enableEdit"
            class="ms-2 edit-save link-primary text-decoration-none cursor-pointer"
            (click)="onEditSave()"
            >Save</a
          >
          <a
            *ngIf="canShowReply && isLast && !comment.enableEdit"
            class="link-secondary text-decoration-none cursor-pointer me-2"
            (click)="replyToCommentThread()"
            >Reply</a
          >
          <a
            *ngIf="canShowEdit(comment)"
            class="edit-button link-secondary text-decoration-none cursor-pointer"
            (click)="onEditClick(comment.id, comment.text, i)"
            >Edit</a
          >
          <i
            *ngIf="canShowDelete(comment)"
            class="delete-button ms-2 bi bi-trash text-danger cursor-pointer"
            (click)="onDeleteComment(i, comment.id)"
          ></i>
        </div>
      </div>
    </div>
    <div
      *ngIf="showReplyTextarea"
      [formGroup]="commentForm"
      class="mt-4 reply-mlt"
    >
      <ff-textarea
        formControlName="text"
        [showTitle]="false"
        [autoSize]="false"
        [maxlength]="100"
        placeHolder="Add Comments"
        (OnKeyUp)="keyUp($event)"
      ></ff-textarea>
      <p class="pt-1 fw-bold text-muted">
        {{ commentContent }}/{{ maxCharacterLimit }}
      </p>
      <div class="mt-2 d-flex justify-content-end gap-2">
        <ffx-button
          [buttonType]="buttonTypeEnum.Secondary"
          textValue="Cancel"
          [enabled]="true"
          [buttonSize]="buttonSizeEnum.Medium"
          [buttonTooltip]="false"
          (OnClick)="closeReply()"
        ></ffx-button>
        <ffx-button
          [buttonType]="buttonTypeEnum.Primary"
          textValue="Save"
          [enabled]="true"
          [buttonSize]="buttonSizeEnum.Medium"
          [buttonTooltip]="false"
          (OnClick)="saveReply()"
        ></ffx-button>
      </div>
    </div>
  </div>
</div>
