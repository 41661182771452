import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { QuestionEvent } from 'src/app/components/steps/step-detail/step-detail.component';
import { TableData } from '../table-features/table-features.service';
import { TableOperationType } from 'src/app/common/services/flows/flow.service';
import { RowEvent } from '../table-question/table-question.component';
import { FormGroup } from '@angular/forms';
import { TableQuestion } from 'src/app/models/TableQuestion';

const rowIndexConst: string = 'rowIndex';

@Directive()
export abstract class TableViewBase {
  @Input() flowId!: string;
  @Input() form!: FormGroup;
  @Input() question!: TableQuestion;
  @Input() tableData!: TableData;
  @Input() readOnly!: boolean;
  @Input() isRowsFixed = false;
  @Input() isRemoveButtonHidden = false;
  @Input() enableRowDuplication = false;

  @Output() tableValueUpdateEvent = new EventEmitter<QuestionEvent>();
  @Output() rowEvent = new EventEmitter<RowEvent>();

  onQuestionEvent(
    questionEvent: QuestionEvent,
    rowIndex: number,
    columnId: string,
  ): void {
    // update question data for card view
    let questionData = this.tableData.questionData.find(
      (questionData) => questionData.rowIndex === rowIndex,
    );

    // find the column id
    let colId: string | null = null;
    if (questionData) {
      Object.entries(questionData).forEach(([key, data]) => {
        if (key === columnId) {
          data.value = questionEvent.newValue;
          colId = key;
        }
      });
    }

    if (!colId) return;

    // update cell data for sorting
    let cellData = this.tableData.cellData.find(
      (cellData) => cellData[rowIndexConst] === rowIndex,
    );

    if (!cellData) return;
    cellData[colId] = questionEvent.newValue;

    this.tableValueUpdateEvent.emit(questionEvent);
  }

  // Used to emit the event to the parent component
  rowRemove(rowId: string): void {
    if (this.isRemoveButtonHidden || this.tableData.cellData.length <= 1)
      return;

    this.rowEvent.emit(new RowEvent(rowId, TableOperationType.RemoveRow));
  }

  // Used to emit the event to the parent component
  rowDuplicate(rowId: string): void {
    if (!this.enableRowDuplication) return;

    this.rowEvent.emit(new RowEvent(rowId, TableOperationType.DuplicateRow));
  }
}
