<div class="list-item d-flex align-items-center w-100 mb-2">
  <div class="flex-grow-1 fs-6 overflow-hidden">
    <div class="d-flex align-items-center gap-2">
      <div class="d-flex justify-content-center align-items-center">
        <span
          class="bi bi-file-earmark text-primary"
          [class.text-danger]="!isFileValid"
        ></span>
      </div>
      <button
        type="button"
        class="btn btn-link text-start text-primary p-0 m-0 flex-grow-1 btn-text text-overflow-ellipsis text-nowrap overflow-hidden file-link"
        [ngbTooltip]="fileName"
        [triggers]="'hover'"
        [placement]="'top-start'"
        [class.text-danger]="!isFileValid"
        (click)="handleClick()"
      >
        {{ fileName }}
      </button>
    </div>
  </div>
  <div *ngIf="!readOnly" class="d-inline-flex align-items-center">
    <div class="d-flex justify-content-center align-items-center">
      <button
        type="button"
        [class.d-inline]="!isFileValid"
        [class.text-danger]="!isFileValid"
        class="btn btn-link text-start p-0 m-0 btn-delete btn-text"
        (click)="handleDeleteClick()"
      >
        <span class="bi bi-trash"></span>
        <span class="visually-hidden">Delete</span>
      </button>
    </div>
  </div>
</div>
