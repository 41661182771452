import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Attachment } from '../../../models/Attachment';
import { FileService } from '../../../common/services/file.service';
import { FileUploadStorageType } from '../../../models/FileUpload';
import { finalize } from 'rxjs';

@Component({
  selector: 'step-attachments',
  templateUrl: './step-attachments.component.html',
})
export class StepAttachmentsComponent {
  @Input() attachments!: Attachment[];
  @Input() isStepCompleted!: boolean;

  @Output() stepAttachmentDelete: EventEmitter<string> =
    new EventEmitter<string>();

  fileUploadStorageTypeEnum: typeof FileUploadStorageType =
    FileUploadStorageType;

  constructor(private _fileService: FileService) {}

  fileDownload(attachment: Attachment) {
    this._fileService.downloadFileByStorageType(
      attachment.storageType,
      attachment.displayName!,
      attachment.relativeFilePath!,
    );
  }

  fileDelete(attachment: Attachment) {
    this._fileService
      .deleteListItemFile(attachment.storageType, attachment.relativeFilePath!)
      .pipe(
        // File will be deleted from form regardless of result
        finalize(() => {
          this.stepAttachmentDelete.emit(attachment.id);
        }),
      )
      .subscribe({
        error: () => {
          // Handle error
        },
      });
  }

  get attachmentTitle(): string {
    return this.isStepCompleted ? 'Attachments' : 'Add attachments (optional)';
  }
}
