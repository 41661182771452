import { Pipe } from '@angular/core';
import { BaseFFxDatePipe } from './base-ffx-date.pipe';

@Pipe({
  name: 'customDayPipe',
})
export class CustomDayPipe extends BaseFFxDatePipe {
  override get formatString(): string {
    return 'EEEE d';
  }
}
