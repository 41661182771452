import { Pipe } from '@angular/core';
import { BaseFFxDatePipe } from './base-ffx-date.pipe';

@Pipe({
  name: 'mediumDatePipe',
})
export class MediumDatePipe extends BaseFFxDatePipe {
  override get formatString(): string {
    return 'mediumDate';
  }
}
