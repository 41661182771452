<div class="card">
  <p-table
    #dt
    (firstChange)="firstIndexChange($event)"
    [value]="tableData.cellData"
    [resizableColumns]="true"
    [paginator]="true"
    [rows]="5"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="[5, 10, 20]"
    [globalFilterFields]="['0', '1', '2', '3']"
    [scrollable]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    columnResizeMode="expand"
    (onPage)="tablePage($event)"
    (onSort)="tableSort($event, false)"
    (onFilter)="tableFilter($event)"
    stateStorage="session"
    [stateKey]="question.id"
  >
    <ng-template pTemplate="caption">
      <div class="flex">
        <span class="p-input-icon-left ml-auto">
          <input
            ff-button
            pInputText
            type="text"
            class="form-control d-flex rounded-1"
            (input)="onFilter($event)"
            placeholder="Search keyword"
            [(ngModel)]="keywordSearch"
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr style="display: block">
        <th
          scope="col"
          *ngIf="this.question.showRowNumbers"
          [pSortableColumn]="'rowIndex'"
          class="table-th-row-number"
        >
          <p-sortIcon [field]="'rowIndex'"></p-sortIcon>
        </th>
        <th
          scope="col"
          *ngFor="let col of tableData.columns"
          [pSortableColumn]="col.id"
          pResizableColumn
          [ngClass]="{
            'data-col': col.question.questionType !== questionType.WetSignature,
            'data-wetsignature-col':
              col.question.questionType === questionType.WetSignature
          }"
        >
          <ff-label
            [label]="col.title"
            [tooltip]="col.question.description"
            [required]="col.question.required"
            [showTitle]="true"
            [controlId]="col.id"
          />
          <p-sortIcon [field]="col.id"></p-sortIcon>
          <p-columnFilter
            type="text"
            [field]="col.id"
            display="menu"
          ></p-columnFilter>
        </th>
        <ng-container [ngTemplateOutlet]="tableIconPlaceholders">
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr style="display: block">
        <td
          *ngIf="this.question.showRowNumbers"
          class="align-top table-td-row-number"
        >
          #{{ rowData.rowIndex + 1 }}
        </td>
        <td
          class="align-top"
          *ngFor="let col of tableData.columns; let i = index"
          [ngClass]="{
            'data-col': col.question.questionType !== questionType.WetSignature,
            'data-wetsignature-col':
              col.question.questionType === questionType.WetSignature
          }"
        >
          <table-question-selector
            [question]="tableData.columns[i].question"
            [questionId]="tableData.questionData[rowData.rowIndex][col.id].id"
            [tableId]="question.id"
            [form]="form"
            [readOnly]="readOnly"
            [flowId]="flowId"
            [visible]="tableData.questionData[rowData.rowIndex][col.id].visible"
            [enabled]="tableData.questionData[rowData.rowIndex][col.id].enabled"
            [value]="tableData.questionData[rowData.rowIndex][col.id].value"
            [showCommentIcon]="false"
            [showErrorText]="false"
            (questionEvent)="onQuestionEvent($event, rowData.rowIndex, col.id)"
          />
        </td>
        <ng-container *ngIf="!isRowsFixed && !readOnly">
          <td class="align-top">
            <button
              type="button"
              class="btn p-0 border-0 mt-3"
              [ngClass]="{ 'd-none': !enableRowDuplication }"
              (click)="rowDuplicate(rowData.rowId)"
            >
              <i class="bi bi-files text-primary"></i>
            </button>
          </td>
          <td class="align-top">
            <button
              type="button"
              class="btn text-danger p-0 border-0 mt-3"
              [ngClass]="{
                'd-none': isRemoveButtonHidden || tableData.cellData.length <= 1
              }"
              (click)="rowRemove(rowData.rowId)"
            >
              <i class="bi bi-trash3"></i>
            </button>
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr style="display: block" *ngIf="tableData.hasFooterQuestion">
        <td
          *ngIf="this.question.showRowNumbers"
          class="table-th-row-number"
        ></td>
        <td
          class="align-top"
          *ngFor="let col of tableData.footerColumns"
          [ngClass]="{
            'data-col': col.questionType !== questionType.WetSignature,
            'data-wetsignature-col':
              col.questionType === questionType.WetSignature
          }"
        >
          <table-question-selector
            *ngIf="col.id"
            [question]="col"
            [questionId]="col.id"
            [tableId]="question.id"
            [form]="form"
            [readOnly]="readOnly"
            [flowId]="flowId"
            [visible]="col.visible"
            [enabled]="col.enabled"
            [value]="col.value"
            [showCommentIcon]="false"
            [showErrorText]="false"
          />
        </td>
        <ng-container *ngIf="!isRowsFixed && !readOnly">
          <td></td>
          <td></td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #tableIconPlaceholders>
  <ng-container *ngIf="!isRowsFixed && !readOnly">
    <th scope="col">
      <i
        class="bi bi-files text-primary invisible"
        [ngClass]="{ 'd-none': !enableRowDuplication }"
      ></i>
    </th>
    <th scope="col">
      <i
        class="bi bi-trash3 invisible"
        [ngClass]="{
          'd-none': isRemoveButtonHidden || tableData.cellData.length <= 1
        }"
      ></i>
    </th>
  </ng-container>
</ng-template>
