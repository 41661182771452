<div>
  <div
    [ngClass]="{
      title: true
    }"
  >
    <div class="mb-2">
      <ff-label
        [label]="label"
        [tooltip]="tooltip"
        [required]="required"
        [showTitle]="showTitle"
        [controlId]="id"
      />
      <ng-content />
    </div>
  </div>
  <div>
    <textarea
      *ngIf="!isHyperlink"
      [rows]="1"
      [id]="id"
      [value]="value"
      style="resize: none"
      readonly
      class="form-control-plaintext text-nowrap overflow-x-scroll"
    ></textarea>
    <a *ngIf="isHyperlink" [href]="value" [id]="id" target="_blank">
      {{ hyperlinkLabel || value }}
    </a>
  </div>
</div>
